import style from "styled-components/native";
import { Colors } from "@constants";

export const Title12 = style.Text`
  font-size: 12;
  color: ${Colors.foodiBlack};
  font-family: "manrope";
  line-height: 12;
`;

export const Title13 = style.Text<{ color?: string; bold?: boolean }>`
  font-size: 13px;
  color: ${({ color }) => color || Colors.white};
  font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
  letter-spacing: 0;
  line-height: 18px;
`;

export const Title14 = style.Text<{ bold?: boolean }>`
  color: ${Colors.darkGray};
  font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const Title16 = style.Text<{ color?: string; bold?: boolean }>`
  font-size: 16;
  color: ${({ color }) => color || Colors.foodiBlack};
  font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
  line-height: 20;
`;

export const Title18 = style.Text<{
  isBold?: boolean;
  color?: string;
  textAlign?: string;
}>`
  font-size: 18;
  color: ${({ color }) => color || Colors.foodiBlack};
  font-family: ${({ isBold }) => (isBold ? "manrope-bold" : "manrope")};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  line-height: 24;
`;

export const Title22 = style.Text<{
  isBlack?: boolean;
  textAlign?: string;
  color?: string;
  isNotBold?: boolean;
}>`
  font-size: 22;
  color: ${({ isBlack, color }) =>
    isBlack ? Colors.foodiBlack : color || Colors.white};
  font-family: ${({ isNotBold }) => (isNotBold ? "manrope" : "manrope-bold")};
  line-height: 30;
  text-align: ${({ textAlign }) => textAlign || "center"};
`;

export const Title24 = style.Text`
  font-size: 24;
  color: ${Colors.foodiBlack};
  font-family: "manrope";
  font-weight: 900;
  line-height: 28;
  text-align: center;
  letter-spacing: 1;
`;

export const Title30 = style.Text<{ textAlign?: string; isBold?: boolean }>`
  font-size: 30;
  color: ${Colors.foodiBlack};
  font-family: ${({ isBold }) => (isBold ? "manrope-bold" : "manrope")};
  font-weight: 600;
  line-height: 41;
  text-align: ${({ textAlign }) => textAlign || "center"};
  letter-spacing: 0;
`;

export const Title36 = style.Text<{ color?: string; textAlign?: string }>`
  font-size: 36;
  color: ${({ color }) => color || Colors.foodiBlack};
  font-family: "manrope-bold";
  line-height: 49;
  text-align: ${({ textAlign }) => textAlign || "center"};
  letter-spacing: 1;
`;

export const Title48 = style.Text<{ color?: string; textAlign?: string }>`
  font-size: 48;
  color: ${({ color }) => color || Colors.foodiBlack};
  font-family: "manrope-bold";
  line-height: 49;
  text-align: ${({ textAlign }) => textAlign || "center"};
  letter-spacing: 1;
`;
