import {
  AccountValidationInfo,
  CompleteProfile,
  DefaultBanner,
  ExternalServicesHome,
  GetApp,
  GetAppMobile,
  LoginInfo,
  News,
  OnScreenCheck, OrderRatingModal,
  ScreenWithFooter,
  WelcomingSentence,
} from '@atomic';
import { RestaurantCardFilters } from "@atomic";
import {
  ILanguage,
  INode,
  IPos,
  MarketingCard,
  OfferTemplateType,
  OfferTemplateWithdrawalType,
  RecentOrders,
} from "@foodi/core";
import {
  AccountThunks,
  MarketingCardThunks,
  AuthThunks,
  AuthActions,
  PosType,
  PointOfSaleThunks,
  HoldingThunks,
  HomeScreens,
  AccountScreens,
  PointOfSaleActions,
  OffersActions,
  OptinsViewModel, OrderViewModel,
} from "@modules";
import { NavigationProp, useFocusEffect } from "@react-navigation/native";
import { useDevices, useHideZenDesk, useWindowSize } from "@hooks";
import {
  BrandThemeThunks,
  ForceInfoAppUpdateActions,
  GetAppMobileActions,
  GlobalState,
  HeaderActions,
  LoaderActions,
} from "@redux";
import {
  AuthErrors,
  getCloudImageUrl,
  getExpirationTokenInfoFromExpireIn,
  isAccountActivated,
  hasAccessTokenExpired,
} from '@utils';
import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { View, Image, StyleSheet, PixelRatio, Dimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { I18n, setLocale } from "react-redux-i18n";
import _ from "lodash";
import { getApolloClient } from "../../../../apollo";
import { Auth0DecodedHash, Auth0Error } from "auth0-js";
import { PasswordExpirationInfo } from "@atomic/organisms/PasswordExpirationInfo";
import moment from "moment";
import { OptoutsViewModel } from '@modules/profile/viewmodels/OptoutsViewModel';
import { navigationService } from '@navigation';

interface IProps {
  navigation: NavigationProp<any>;
}

interface IButtonProps {
  label: string;
  onPress: () => void;
  testID?: string;
}

export const HomeScreen: React.FC<IProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const [isMobile] = useDevices();

  const [currentHeight] = useWindowSize();

  const routeName = navigationService.getActiveRouteName();
  const isHomePage = routeName === "HomePage";

  const styles = useMemo(() => _styles(isMobile, currentHeight), [
    isMobile,
    currentHeight,
  ]);
  const [pointsOfSale, setPointsOfSale] = useState<INode<IPos>[] | undefined>(
    undefined
  );
  const [newMarketingCards, setNewMarketingCards] = useState<MarketingCard[]>();
  const [pwdHasExpired, setPwdHasExpired] = useState<string>("");

  const [orderToRate, setOrderToRate] = React.useState<RecentOrders | null>(null);

  const { showGetAppMobile } = useSelector(
    (state: GlobalState) => state.getAppMobile
  );

  // selectors
  const { heroBackImage } = useSelector(
    (state: GlobalState) => state.brandTheme.brandTheme
  );
  const userInfo = useSelector((state: GlobalState) => state.auth?.userInfo);
  const isLogin = useSelector((state: GlobalState) => state.auth?.isLogin);
  const forceInfoAppUpdate = useSelector(
    (state: GlobalState) => state.forceInfoAppUpdate.forceUpdate
  );
  const accessToken = useSelector(
    (state: GlobalState) => state.auth?.authToken?.accessToken
  );
  const { holdingName } =
    useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};

  const mercanetStatus = useSelector(
    (state: GlobalState) => state.transactions?.mercanetStatus
  );
  const externalsServices =
    useSelector(
      (state: GlobalState) => state.marketingCard.externalsServices
    ) || [];

  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language || state.i18n?.locale
  );

  const orderVM = new OrderViewModel(dispatch, userInfo?.idGuest || "");

  const isAccountValidated = !!isAccountActivated(accessToken);
  const hasTokenExpired = hasAccessTokenExpired(accessToken);

  const notAuthorizedUser = isAccountValidated || pwdHasExpired;

  const getBrandThemeDebounce = useCallback(_.debounce(getBrandTheme, 100), []);

  const [stillInTimeToShowOrderRatingModal, setStillInTimeToShowOrderRatingModal] = useState<boolean>(true);
  const orderRatingModalTimeout = useRef<any>(null);

  const isExternalLinksActive =
    !!externalsServices?.length &&
    !externalsServices.every((service) => !service.active || !service.content);

  const forceCacheReset = async () => {
    dispatch(LoaderActions.setLoading(true));
    dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(true));
    await getApolloClient().cache.reset();
    dispatch(LoaderActions.setLoading(false));
    dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
  }

  useEffect(() => {
    forceCacheReset();
    dispatch(OffersActions.resetOfferInfo());
    dispatch(PointOfSaleActions.setSelectedPos(null));
    return () => {
      dispatch(OffersActions.resetOfferInfo());
    };
  }, [])

  useFocusEffect(
    useCallback(() => {
      dispatch(OffersActions.setRequestTries(1));

      return () => {
        // executed when the user moves to another page (component loses focus)
        if (orderRatingModalTimeout.current) {
          clearInterval(orderRatingModalTimeout.current);
        }
        setStillInTimeToShowOrderRatingModal(false);
      }
    }, [])
  );

  useEffect(() => {
    if (!isLogin || isAccountValidated || hasTokenExpired) return;
    getHomeData();
  }, [isLogin, isAccountValidated, hasTokenExpired]);

  useEffect(() => {
    if (isLogin && forceInfoAppUpdate) {
      getHomeData();
    }
  }, [forceInfoAppUpdate]);

  useHideZenDesk({ condition: isMobile && showGetAppMobile });

  const getHomeData = async () => {
    try {
      dispatch(LoaderActions.setLoading(true));
      await Promise.all([getUserInfo(), handlePointsOfSale()]);
    } catch (e) {
      dispatch(LoaderActions.setLoading(false));
    } finally {
      dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
      dispatch(PointOfSaleActions.setFullCartStatus(false));
      dispatch(PointOfSaleActions.setMiniCartStatus(true));
    }
  };

  useEffect(() => {
    if (isAccountValidated || hasTokenExpired) return;
    if (userInfo?.id && isLogin && accessToken) {
      if (!mercanetStatus) dispatch(LoaderActions.setLoading(true));
      handleBalance(userInfo.id);
      handleBrand(userInfo.id);
      dispatch(setLocale(userInfo.language ?? userLanguage));
    } else {
      dispatch(setLocale(userLanguage));
    }
    userInfo && getBrandThemeDebounce(userInfo.brandName ?? "");
    moment.locale(userLanguage);
    dispatch(LoaderActions.setLoading(false));

    getOrdersToRateAndShowModal();
  }, [userInfo, userLanguage, hasTokenExpired]);

  const getOrdersToRateAndShowModal = async () => {
    if (isHomePage && userInfo?.rating && userInfo?.idGuest && userInfo?.userOptouts && !OptoutsViewModel.userHasOptout(userInfo.userOptouts, 'ORDER_RATING')) {
      try {

        const { orders } = await orderVM.recentOrdersToRate();

        if (orders.length) {
          // because the component at the start is forcing a cache reset, this component is updated a lot and so, it causes some unnecessary
          // re-renders of the Modal, which causes flickering of the UI component. This is an attempt to await a brief moment before
          // to allow the component to do all the necessary updates, without having to re-render the Modal (the goal is to have no flickering of the modal)
          await new Promise((resolve, reject) => {
            orderRatingModalTimeout.current = setTimeout(() => {
              setOrderToRate(orders[0]);
              resolve(null);
            }, 1000);
          })
        }
      } catch (e) {
        // prevent Unhandled Rejection error page; Toast error already being handled
      }
    }
  }

  const onSuccessCallback = useCallback(async (authToken: Auth0DecodedHash) => {
    dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(true));
    if (authToken.state === "sso") {
      navigation?.navigate(HomeScreens.ACCOUNT_STACK, {
        screen: AccountScreens.STEP_TWO_SCREEN,
        params: {
          accessToken: authToken.accessToken,
        },
      });
      return;
    }

    const expirationTokenInfo = getExpirationTokenInfoFromExpireIn(
      authToken.expiresIn
    );
    dispatch(
      AuthActions.setAuthToken({
        accessToken: authToken.accessToken ?? "",
        refreshToken: authToken.refreshToken ?? "",
        expiresIn: Number(authToken.expiresIn),
        ...expirationTokenInfo,
      })
    );
    if (!isAccountActivated(authToken.accessToken ?? "")) {
      dispatch(LoaderActions.setLoading(true));
      const userExist = await getUserInfo();
      if (userExist !== undefined) {
        dispatch(AuthActions.setLoginStatus(true));
        dispatch(LoaderActions.setLoading(false));
        dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
      } else {
        dispatch(LoaderActions.setLoading(false));
        dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
        return;
      }
    }
    dispatch(GetAppMobileActions.setActive(true));
    dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
  }, []);

  const onErrorCallback = useCallback((authError: Auth0Error) => {
    const { error, errorDescription } = authError;
    if (errorDescription?.includes(AuthErrors.PASSWORD_EXPIRED)) {
      setPwdHasExpired(errorDescription);
    }
  }, []);

  const setOrderToRateCallback = useCallback((discarded?: boolean) => {
    const id = orderToRate?.id!;

    setOrderToRate(null);

    if (discarded) {

      orderVM.discardOrderRatingNotification({ idOrder: id });
    }
  }, [orderToRate]);

  /* istanbul ignore next */
  useEffect(() => {
    if (isLogin) return;
    const unsubscribe = navigation.addListener("focus", () => {
      dispatch(
        AuthThunks.retrieveTokens({
          authResultCallBack: onSuccessCallback,
          authErrorCallback: onErrorCallback,
        })
      );
    });

    return unsubscribe;
  }, [navigation]);

  const getPointsOfSale = (type: string) => {
    return dispatch(
      PointOfSaleThunks.getPointsOfSale({
        type,
        offerTemplateType: `${OfferTemplateType.CLICK_COLLECT},${OfferTemplateType.TABLE_SERVICE},${OfferTemplateType.BOOKING_SERVICE}`,
        offerTemplateWithdrawalType: `${OfferTemplateWithdrawalType.CONNECTED_LOCKERS},${OfferTemplateWithdrawalType.POS_AT_SITE},${OfferTemplateWithdrawalType.POS_TAKE_AWAY},${OfferTemplateWithdrawalType.TABLE_SERVICE},${OfferTemplateWithdrawalType.POS_CLICK_SERVE},${OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT},${OfferTemplateWithdrawalType.CLICK_AND_PICK_UP}`,
      })
    );
  };

  const handlePointsOfSale = async () => {
    if (!mercanetStatus) {
      dispatch(LoaderActions.setLoading(true));
    }

    const result = await getPointsOfSale(
      `${PosType.SELF},${PosType.CAFETERIA},${PosType.BRASSERIE},${PosType.OTHER}`
    );

    const pointsOfSaleList: INode<IPos>[] = (result as any)?.data;

    if (!!pointsOfSaleList?.length) {
      setPointsOfSale(pointsOfSaleList);
    }

    dispatch(LoaderActions.setLoading(false));
  };

  const handleBalance = (id: string) => {
    dispatch(
      AccountThunks.getBalance({
        id,
      })
    );
  };
  const getUserInfo = async () => {
    try {
      const res = await dispatch(AuthThunks.getUserInfo());

      //@ts-ignore
      if (res?.hardLinked) {
        const res = await dispatch(HoldingThunks.migrateToHoldingView());

        //@ts-ignore
        if (res) {
          await getApolloClient().cache.reset();

          //@ts-ignore
          navigation?.push(HomeScreens.HOME_SCREEN);
        }
      }
      return res;
    } catch (e) {}
  };

  async function getBrandTheme(holding_name: string) {
    try {
      await dispatch(
          BrandThemeThunks.getBrandTheme({
            application_id: "foodiweb",
            holding_name: holding_name.toLowerCase(),
          })
      );
    }
    catch (e) {
      // prevent Unhandled Rejection error page; Toast error already being handled
    }
  }

  const handleBrand = (id: string) => {
    dispatch(
      MarketingCardThunks.getBrand({
        id,
      })
      //@ts-ignore
    ).then((result) => {
      handleNewMarketingCards(result?.id);
    });
  };

  const handleNewMarketingCards = (idHolding: string) => {
    if (idHolding !== undefined) {
      dispatch(
        MarketingCardThunks.getPublishedMarketingCardsNew({
          idHolding,
          type: "ARTICLE,IMPORTANT_MESSAGE",
        })
        //@ts-ignore
      ).then((res) => {
        setNewMarketingCards(res.marketingCardsNew);
      });
    }
  };

  const heroBackImageUri = getCloudImageUrl(
    heroBackImage,
    "width",
    String(PixelRatio.getPixelSizeForLayoutSize(Dimensions.get("window").width))
  );

  const handleGetAppMobile = () => {
    dispatch(GetAppMobileActions.setActive(false));
  };

  const ErrorsContent = () => {
    if (isAccountValidated) {
      return <AccountValidationInfo />;
    }
    if (pwdHasExpired) {
      return <PasswordExpirationInfo info={pwdHasExpired} />;
    }
    return null;
  };

  const HomePageContent: React.FC = () => (
    <>
      {!isLogin || (isLogin && !holdingName) ? (
        <DefaultBanner
          isLogin={isLogin}
          isMobile={isMobile}
          username={_.capitalize(userInfo?.firstName?.toLowerCase())}
        />
      ) : (
        <Image
          style={styles.image}
          source={{
            uri: heroBackImageUri,
            cache: "only-if-cached",
          }}
        />
      )}
      <OnScreenCheck
        handleIsVisible={(value) =>
          dispatch(HeaderActions.setHeaderOpacity(!value))
        }
      />
      {isLogin &&
        (holdingName ? (
          <WelcomingSentence
            firstLabel={I18n.t("homepage.welcomingSentence.greeting")}
            isLogged={isLogin}
            userName={_.capitalize(userInfo?.firstName?.toLowerCase())}
            secondLabel={`${I18n.t("homepage.welcomingSentence.desire")}`}
            fallbackLabel={I18n.t("homepage.welcomingSentence.welcome")}
          />
        ) : (
          <CompleteProfile
            subtitle={I18n.t(
              "homepage.welcomingSentence.profileIncompleteDesc"
            )}
            navigation={navigation}
          />
        ))}
      {!isLogin && <LoginInfo navigation={navigation} />}
      {isLogin && pointsOfSale && (
        <RestaurantCardFilters
          restaurantCardsList={pointsOfSale}
          navigation={navigation}
        />
      )}
      {isLogin && isMobile && isExternalLinksActive && (
        <ExternalServicesHome
          services={externalsServices}
          isMobile={isMobile}
        />
      )}
      {isLogin && !!newMarketingCards?.length && (
        <News news={newMarketingCards} navigation={navigation} />
      )}
      {!isMobile && <GetApp />}
    </>
  );

  const homeContent = notAuthorizedUser ? (
    <ErrorsContent />
  ) : (
    <HomePageContent />
  );

  return (
    <View style={styles.mainContainer}>
      <View style={{ flex: 1 }}>
        <ScreenWithFooter navigation={navigation}>
          <View style={styles.container}>{homeContent}</View>
        </ScreenWithFooter>
      </View>
      {isMobile && showGetAppMobile && (
        <GetAppMobile closeGetApp={handleGetAppMobile} />
      )}
      {isLogin && stillInTimeToShowOrderRatingModal && orderToRate && (
          <OrderRatingModal
              open={!!orderToRate}
              onClose={setOrderToRateCallback}
              posName={orderToRate.withdrawLocation}
              idOrder={orderToRate.id}
              withdrawalRange={orderToRate.withdrawRange}
              idHuman={orderToRate.idHuman}
              currentHeight={currentHeight}
              navigation={navigation}
          />
      )}
    </View>
  );
};

const _styles = (isMobile: boolean, currentHeight?: number) =>
  StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: "white",
    },
    image: {
      width: "100%",
      height: isMobile ? 215 : 366,
    },
    mainContainer: {
      height: `${currentHeight}px`,
    },
  });
