"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FOrderRepository = void 0;
const __1 = require("..");
const __2 = require("../../..");
const __3 = require("../../..");
const domain_1 = require("../../../domain");
const mappers_1 = require("../mappers");
class FOrderRepository {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    getOrders(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const ordersMapper = new mappers_1.OrdersMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __3.GET_ORDERS,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(ordersMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.OrderRepositoryErrors.GetOrdersRequestError("GET_ORDERS_REQUEST_FAILED", error));
            }
        });
    }
    getAllOrders(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const allOrdersMapper = new mappers_1.AllOrdersMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_ALL_ORDERS,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(allOrdersMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.OrderRepositoryErrors.GetAllOrdersRequestError("GET_ALL_ORDERS_REQUEST_FAILED", error));
            }
        });
    }
    getOrder(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const orderMapper = new mappers_1.OrderMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __3.GET_ORDER,
                    variables,
                });
                return Promise.resolve(orderMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OrderRepositoryErrors.GetOrderRequestError("GET_ORDER_REQUEST_FAILED", error));
            }
        });
    }
    getBookingOrders(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const bookingOrdersMapper = new mappers_1.BookingOrdersMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __3.GET_BOOKING_ORDERS,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(bookingOrdersMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.OrderRepositoryErrors.GetBookingOrdersRequestError("GET_BOOKING_ORDERS_REQUEST_FAILED", error));
            }
        });
    }
    upsertOrder(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const upsertOrderMapper = new mappers_1.UpsertOrderMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __2.UPSERT_ORDER,
                    variables: { input },
                });
                return Promise.resolve(upsertOrderMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.OrderRepositoryErrors.UpsertOrderRequestError("UPSERT_ORDER_REQUEST_FAILED", { error }));
            }
        });
    }
    confirmOrder(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const upsertOrderMapper = new mappers_1.UpsertOrderMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.CONFIRM_ORDER,
                    variables,
                });
                return Promise.resolve(upsertOrderMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OrderRepositoryErrors.ConfirmOrderRequestError("CONFIRM_ORDER_REQUEST_FAILED", error));
            }
        });
    }
    cancelOrder(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const upsertOrderMapper = new mappers_1.UpsertOrderMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.CANCEL_ORDER,
                    variables,
                });
                return Promise.resolve(upsertOrderMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OrderRepositoryErrors.CancelOrderRequestError("CANCEL_ORDER_REQUEST_FAILED", { error }));
            }
        });
    }
    cartSummaryAdmissionAndPrice(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const cartSummaryAdmissionAndPriceMapper = new mappers_1.CartSummaryAdmissionAndPriceMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.CART_SUMMARY_ADMISSION_AND_PRICE,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(cartSummaryAdmissionAndPriceMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.OrderRepositoryErrors.CartSummaryAdmissionAndPriceRequestError("CART_SUMMARY_ADMISSION_AND_PRICE_REQUEST_FAILED", { error }));
            }
        });
    }
    addOrderRating(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const addOrderRatingMapper = new mappers_1.AddOrderRatingMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.ADD_ORDER_RATING,
                    variables
                });
                return Promise.resolve(addOrderRatingMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OrderRepositoryErrors.AddOrderRatingRequestError(error instanceof Error ? error.message : 'OTHER', { error }));
            }
        });
    }
    discardOrderRatingNotification(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const discardOrderRatingNotificationMapper = new mappers_1.DiscardOrderRatingNotificationMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.DISCARD_ORDER_RATING_NOTIFICATION,
                    variables: {
                        id: variables === null || variables === void 0 ? void 0 : variables.idOrder
                    }
                });
                return Promise.resolve(discardOrderRatingNotificationMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OrderRepositoryErrors.DiscardOrderRatingRequestError(error instanceof Error ? error.message : 'OTHER', { error }));
            }
        });
    }
    recentOrdersToRate() {
        return __awaiter(this, void 0, void 0, function* () {
            const recentOrdersToRateMapper = new mappers_1.RecentOrdersToRateMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.RECENT_ORDERS_TO_RATE
                });
                return Promise.resolve(recentOrdersToRateMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OrderRepositoryErrors.RecentOrdersToRateError("RECENT_ORDERS_TO_RATE_FAILED", error));
            }
        });
    }
    getOrderCC(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const orderCCMapper = new mappers_1.OrderCCMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_ORDER_CC,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(orderCCMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OrderRepositoryErrors.GetOrderCCRequestError("GET_ORDER_CC_REQUEST_FAILED", error));
            }
        });
    }
    getBooking(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const orderCCMapper = new mappers_1.OrderCCMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __3.GET_BOOKING,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(orderCCMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OrderRepositoryErrors.GetOrderCCRequestError("GET_ORDER_CC_REQUEST_FAILED", error));
            }
        });
    }
    createBookingOrder(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const upsertOrderMapper = new mappers_1.UpsertOrderMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.CREATE_BOOKING_ORDER,
                    variables: { input },
                });
                return Promise.resolve(upsertOrderMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.OrderRepositoryErrors.CreateBookingOrderRequestError("CREATE_BOOKING_ORDER_REQUEST_FAILED", { error }));
            }
        });
    }
    updateBookingOrderSlot(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const upsertOrderMapper = new mappers_1.UpsertOrderMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.UPDATE_BOOKING_ORDER_SLOT,
                    variables: { input },
                });
                return Promise.resolve(upsertOrderMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.OrderRepositoryErrors.UpdateBookingOrderSlotRequestError("UPDATE_BOOKING_ORDER_SLOT_REQUEST_FAILED", { error }));
            }
        });
    }
    cancelBookingOrder(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const upsertOrderMapper = new mappers_1.UpsertOrderMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.CANCEL_BOOKING_ORDER,
                    variables,
                });
                return Promise.resolve(upsertOrderMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.OrderRepositoryErrors.CancelBookingOrderRequestError("CANCEL_BOOKING_ORDER_REQUEST_FAILED", { error }));
            }
        });
    }
    getOrderReceipt(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const orderReceiptMapper = new mappers_1.OrderReceiptMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_ORDER_RECEIPT,
                    variables,
                });
                return Promise.resolve(orderReceiptMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.OrderRepositoryErrors.GetOrderReceiptRequestError("GET_ORDER_RECEIPT_REQUEST_FAILED", { error }));
            }
        });
    }
}
exports.FOrderRepository = FOrderRepository;
