import {
  OfferTemplatesResponse,
  GetOfferTemplatesCommand,
  IOfferTemplate,
  GetOfferSlotsCommand,
  OfferSlotsResponse,
  OrderItem,
  QuantityRuleType,
  NextOrderableCCOffer,
  GetOfferCommand,
  OfferResponse,
  ActiveOffer,
} from "@foodi/core";
import { HeartMealsViewModel } from "@modules";
import { createReducer, GlobalState, ThunkResult } from "@redux";
import { ISlots } from "@modules";
import { Action } from "@redux/action";
import { createSelector } from "reselect";
import { displayToastError } from "@utils";

export interface IOrderableOffer extends NextOrderableCCOffer {
  isSelected?: boolean;
}
export interface OffersState {
  selectedOffer: IOfferTemplate | null;
  selectedOfferSlot: ISlots | null;
  orderItems: OrderItem[];
  outOfStockItems: any[];
  outOfStockModalOpen: boolean;
  nextOrderableOffers: IOrderableOffer[];
  activeOrderableOffer: ActiveOffer | null;
  maxRequestTries: number;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_NEXT_ORDERABLE_OFFERS: "Offers/SET_NEXT_ORDERABLE_OFFERS",
  SET_ACTIVE_ORDERABLE_OFFER: "Offers/SET_ACTIVE_ORDERABLE_OFFER",
  SET_SELECTED_OFFER: "Offers/SET_SELECTED_OFFER",
  SET_SELECTED_OFFER_SLOT: "Offers/SET_SELECTED_OFFER_SLOT",
  SET_ORDER_ITEMS: "Offers/SET_ORDER_ITEMS",
  SET_OUT_OF_STOCK_ORDER_ITEMS: "Offers/SET_OUT_OF_STOCK_ORDER_ITEMS",
  RESET_OUT_OF_STOCK_ORDER_ITEMS: "Offers/RESET_OUT_OF_STOCK_ORDER_ITEMS",
  REMOVE_ORDER_ITEMS: "Offers/REMOVE_ORDER_ITEMS",
  INIT_ORDER_ITEM: "Offers/INIT_ORDER_ITEM",
  RESET_OFFER_INFO: "Offers/RESET_OFFER_INFO",
  SET_TRIES: "Offers/SET_TRIES",
};

const ActionCreators = {
  setNextOrderableOffers: (
    nextOrderableOffers: IOrderableOffer[]
  ): Action<IOrderableOffer[]> => ({
    type: ActionTypes.SET_NEXT_ORDERABLE_OFFERS,
    payload: nextOrderableOffers,
  }),
  setActiveOrderableOffer: (
    activeOrderableOffer: ActiveOffer | null
  ): Action<ActiveOffer | null> => ({
    type: ActionTypes.SET_ACTIVE_ORDERABLE_OFFER,
    payload: activeOrderableOffer,
  }),
  setSelectedOffer: (
    selectedOffer: IOfferTemplate | null
  ): Action<IOfferTemplate | null> => ({
    type: ActionTypes.SET_SELECTED_OFFER,
    payload: selectedOffer,
  }),
  setSelectedOfferSlot: (
    selectedOfferSlot: ISlots | null
  ): Action<ISlots | null> => ({
    type: ActionTypes.SET_SELECTED_OFFER_SLOT,
    payload: selectedOfferSlot,
  }),
  addOrderItem: (orderItem: OrderItem): Action<OrderItem> => ({
    type: ActionTypes.SET_ORDER_ITEMS,
    payload: orderItem,
  }),
  removeOrderItems: (orderItems: OrderItem[]): Action<OrderItem[]> => ({
    type: ActionTypes.REMOVE_ORDER_ITEMS,
    payload: orderItems,
  }),
  setOutOfStockOrderItems: (orderItems: any[]): Action<any[]> => ({
    type: ActionTypes.SET_OUT_OF_STOCK_ORDER_ITEMS,
    payload: orderItems,
  }),
  resetOutOfStockOrderItems: (): Action<any[]> => ({
    type: ActionTypes.RESET_OUT_OF_STOCK_ORDER_ITEMS,
    payload: [],
  }),
  initOrderItems: (): Action<[]> => ({
    type: ActionTypes.INIT_ORDER_ITEM,
    payload: [],
  }),
  resetOfferInfo: (): Action<any> => ({
    type: ActionTypes.RESET_OFFER_INFO,
    payload: null,
  }),
  setRequestTries: (maxRequestTries: number): Action<any> => ({
    type: ActionTypes.SET_TRIES,
    payload: maxRequestTries,
  }),
};

const ThunkActionCreators = {
  getOfferTemplates: (
    params: GetOfferTemplatesCommand
  ): ThunkResult<Promise<OfferTemplatesResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      dispatch(ActionCreators.resetOfferInfo());
      const { getOfferTemplates } = getDependencies();
      return getOfferTemplates.execute(params);
    } catch (e: any) {
      displayToastError(dispatch);
      throw new Error(e?.message);
    }
  },
  getOfferSlots: (
    params: GetOfferSlotsCommand
  ): ThunkResult<Promise<OfferSlotsResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getOfferSlots } = getDependencies();
      return getOfferSlots.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  getOffer: (
    params: GetOfferCommand
  ): ThunkResult<Promise<OfferResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getOffer } = getDependencies();
      return getOffer.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
};

/*************  Reducer  ****************/

const initialState: OffersState = {
  nextOrderableOffers: [],
  selectedOffer: null,
  selectedOfferSlot: null,
  orderItems: [],
  outOfStockItems: [],
  outOfStockModalOpen: false,
  activeOrderableOffer: null,
  maxRequestTries: 1,
};

const getOrderItems = (
  orderItems: OrderItem[],
  newItem: OrderItem
): OrderItem[] => {
  const orderItemsCopy: OrderItem[] = JSON.parse(JSON.stringify(orderItems));
  const itemIndex = orderItemsCopy.findIndex((_item) => {
    return (
      _item.id === newItem.id &&
      (!newItem.chosenBaking || _item.chosenBaking === newItem.chosenBaking)
    );
  });
  if (itemIndex === -1) orderItemsCopy.push(newItem);
  else {
    let quantity =
      newItem.quantity < 0
        ? orderItemsCopy[itemIndex].quantity + newItem.quantity
        : newItem.quantity;
    orderItemsCopy[itemIndex] = { ...newItem, quantity };
  }
  const newArray = orderItemsCopy.filter((orderItem) => orderItem.quantity);
  return newArray;
};

const removeOrderItems = (
  current: OrderItem[],
  toRemove: any[]
): OrderItem[] => {
  return current?.filter(
    (o) => !toRemove?.some((r) => r?.idOfferItem === o?.id)
  );
};

const getOutOfStockItems = (current: OrderItem[], other: any[]) => {
  return other?.map((o) => {
    const item = current?.find((c) => c?.id === o?.idOfferItem);
    return { ...o, title: item?.labelWhenAdded, quantity: item?.quantity };
  });
};

const Reduction = {
  setNextOrderableOffers: (
    state: OffersState,
    { payload: nextOrderableOffers }: Action<IOrderableOffer[]>
  ): OffersState => ({
    ...state,
    nextOrderableOffers,
  }),
  setActiveOrderableOffer: (
    state: OffersState,
    { payload: activeOrderableOffer }: Action<ActiveOffer>
  ): OffersState => ({
    ...state,
    activeOrderableOffer,
  }),
  setSelectedOffer: (
    state: OffersState,
    { payload: selectedOffer }: Action<IOfferTemplate>
  ): OffersState => ({
    ...state,
    selectedOffer,
  }),
  setSelectedOfferSlot: (
    state: OffersState,
    { payload: selectedOfferSlot }: Action<ISlots | null>
  ): OffersState => ({
    ...state,
    selectedOfferSlot,
  }),
  setOrderItems: (
    state: OffersState,
    { payload: orderItem }: Action<OrderItem>
  ): OffersState => ({
    ...state,
    orderItems: getOrderItems(state.orderItems, orderItem),
  }),
  removeOrderItems: (
    state: OffersState,
    { payload: toRemove }: Action<OrderItem[]>
  ): OffersState => ({
    ...state,
    orderItems: removeOrderItems(state.orderItems, toRemove),
    outOfStockModalOpen: false,
  }),
  setOutOfStockOrderItems: (
    state: OffersState,
    { payload: outOfStockItems }: Action<any[]>
  ): OffersState => ({
    ...state,
    outOfStockItems: getOutOfStockItems(state.orderItems, outOfStockItems),
    outOfStockModalOpen: true,
  }),
  resetOutOfStockOrderItems: (state: OffersState): OffersState => ({
    ...state,
    outOfStockItems: [],
  }),
  initOrderItems: (state: OffersState): OffersState => ({
    ...state,
    orderItems: [],
  }),
  resetOfferInfo: (state: OffersState): OffersState => ({
    ...state,
    selectedOffer: null,
    nextOrderableOffers: [],
    activeOrderableOffer: null
  }),
  setRequestTries: (
    state: OffersState,
    { payload: maxRequestTries }: Action<number>
  ): OffersState => ({
    ...state,
    maxRequestTries
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_NEXT_ORDERABLE_OFFERS]: Reduction.setNextOrderableOffers,
  [ActionTypes.SET_ACTIVE_ORDERABLE_OFFER]: Reduction.setActiveOrderableOffer,
  [ActionTypes.SET_SELECTED_OFFER]: Reduction.setSelectedOffer,
  [ActionTypes.SET_SELECTED_OFFER_SLOT]: Reduction.setSelectedOfferSlot,
  [ActionTypes.SET_ORDER_ITEMS]: Reduction.setOrderItems,
  [ActionTypes.SET_OUT_OF_STOCK_ORDER_ITEMS]: Reduction.setOutOfStockOrderItems,
  [ActionTypes.RESET_OUT_OF_STOCK_ORDER_ITEMS]:
    Reduction.resetOutOfStockOrderItems,
  [ActionTypes.REMOVE_ORDER_ITEMS]: Reduction.removeOrderItems,
  [ActionTypes.INIT_ORDER_ITEM]: Reduction.initOrderItems,
  [ActionTypes.RESET_OFFER_INFO]: Reduction.resetOfferInfo,
  [ActionTypes.SET_TRIES]: Reduction.setRequestTries,
});

//SELECTOR

const selectedOfferSelector = (state: GlobalState) =>
  state.offers.selectedOffer;
const orderItemsSelector = (state: GlobalState) => state.offers.orderItems;

export const isHeartMealsRuleValidSelector = createSelector(
  [selectedOfferSelector, orderItemsSelector],
  (selectedOffer: IOfferTemplate | null, orderItemsSelector: OrderItem[]) => {
    if (!!selectedOffer?.mealHeartRule && !!orderItemsSelector) {
      if (selectedOffer.mealHeartRule.selectedFamilies?.length === 0) {
        return true;
      }

      const heartMealsVM = new HeartMealsViewModel(
        selectedOffer.mealHeartRule,
        orderItemsSelector
      );
      return heartMealsVM.isMealsHeartRulesValid();
    }
    return true;
  }
);

export const isOverHeartMealsProductsSelector = createSelector(
  [selectedOfferSelector, orderItemsSelector],
  (selectedOffer: IOfferTemplate | null, orderItemsSelector: OrderItem[]) => {
    if (!!selectedOffer?.mealHeartRule && !!orderItemsSelector) {
      const heartMealsVM = new HeartMealsViewModel(
        selectedOffer.mealHeartRule,
        orderItemsSelector
      );

      const maxHeartMeal = heartMealsVM.getMealsRules(
        QuantityRuleType.mealQuantity
      ).max;
      const heatMealQuantity = heartMealsVM.getHeatMealQuantity();
      return heatMealQuantity >= maxHeartMeal;
    }
    return false;
  }
);

export const isOverProductsLimitSelector = createSelector(
  [selectedOfferSelector, orderItemsSelector],
  (selectedOffer: IOfferTemplate | null, orderItemsSelector: OrderItem[]) => {
    if (!!selectedOffer?.mealHeartRule && !!orderItemsSelector) {
      const heartMealsVM = new HeartMealsViewModel(
        selectedOffer.mealHeartRule,
        orderItemsSelector
      );
      const getMaxProductsPerOrder = heartMealsVM.getMaxProductsPerOrder();
      return heartMealsVM.getTotalQuantity() >= getMaxProductsPerOrder;
    }
    return false;
  }
);

export default reducer;

export {
  reducer as OffersReducer,
  ActionTypes as OffersActionTypes,
  ActionCreators as OffersActions,
  ThunkActionCreators as OffersThunks,
};
