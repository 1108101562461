import { NavigationProp, Route } from "@react-navigation/native";
import { View, StyleSheet } from "react-native";
import React, { useEffect, useMemo } from "react";
import {
  BookingTemplate,
  CalendarDays,
  ClickAndCollectTemplate,
  FullBookingCart,
  FullCart,
  LoaderAnimationComponent,
  MiniBookingCart,
  MiniCart,
  OutOfStockModal,
  RestaurantInfo,
  ScreenWithFooter,
} from "@atomic";
import { Spacing, Colors } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState, LoaderActions } from "@redux";
import { POSButtons } from "@atomic/molecules/POSButtons";
import { Title22, Title36 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import {
  CalendarFlow,
  HoldingSettingActions,
  OffersActions,
  PointOfSaleActions,
  PointOfSaleThunks,
} from "@modules";
import { useDevices, useHideZenDesk } from "@hooks";
import { PosResponse } from "@foodi/core";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
}

export const RestaurantDetailScreen: React.FC<IProps> = React.memo(
  ({ navigation, route }) => {
    const { isModify, isRefillFromCart, id }: any = route?.params || {};

    const dispatch = useDispatch();

    const [isMobile] = useDevices();

    const isBookingSelected = useSelector(
      (state: GlobalState) => state?.booking?.isBookingSelected
    );
    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const pointOfSale = useSelector((state: GlobalState) => state?.pointOfSale);
    const isMiniCartAvailable = pointOfSale?.miniCartEnabled;
    const isFullCartAvailable = pointOfSale?.fullCartEnabled;
    const isMiniBookingCartAvailable = pointOfSale?.miniBookingCartEnabled;
    const isFullBookingCartAvailable = pointOfSale?.fullBookingCartEnabled;

    const hideMenuBooking = useSelector(
      (state: GlobalState) =>
        state.pointOfSale?.selectedPos?.pointOfSale?.hideMenuBooking
    );
    const styles = useMemo(() => _styles(isMobile), [
      isMobile,
    ]);

      useEffect(() => {
          dispatch(OffersActions.resetOfferInfo());
          dispatch(PointOfSaleActions.setSelectedPos(null));
          return () => {
              dispatch(OffersActions.resetOfferInfo());
              dispatch(PointOfSaleActions.setSelectedPos(null));
          };
      }, []);

    const selectedPointOfSale = useSelector(
      (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale
    );

    const handlePosResponse = (res: PosResponse) => {
        const holdingSettings = res?.pointOfSale?.zone.holding?.settings;
        if (holdingSettings) {
            dispatch(HoldingSettingActions.setProductTransparencyPreferences(holdingSettings.productTransparencyPreferences));
            dispatch(HoldingSettingActions.setOffersProductTransparencyPreferences(holdingSettings.offersProductTransparencyPreferences));
        }
    };

    const getPos = () => {
        dispatch(LoaderActions.setLoading(true));
        dispatch(
            PointOfSaleThunks.getPos({
                id,
                days: 30,
            })
        )
            //@ts-ignore
            .then(handlePosResponse)
            .finally(() => {
                dispatch(LoaderActions.setLoading(false));
            });
      };

    useEffect(() => {
        if (id) getPos();

        return () => {
          dispatch(HoldingSettingActions.setProductTransparencyPreferences(null));
          dispatch(HoldingSettingActions.setOffersProductTransparencyPreferences(null));
          dispatch(PointOfSaleActions.setSelectedPos(null));
        }
      }, [id, userLanguage]);

    const isCartAvailable =
      isMiniCartAvailable ||
      isFullCartAvailable ||
      isMiniBookingCartAvailable ||
      isFullBookingCartAvailable;

    const shouldHideZenDesk = isMobile && isCartAvailable;

    useHideZenDesk({ condition: shouldHideZenDesk });

    // Prevent access to the detail page if the selected point od sale is not yet loaded.
    if (!selectedPointOfSale) {
      return (
        <View style={styles.loadingStyle}>
          <LoaderAnimationComponent />
        </View>
      );
    }

    return (
      <>
        <ScreenWithFooter navigation={navigation}>
          <View style={styles.container}>
            <RestaurantInfo navigation={navigation} route={route} />
            {!hideMenuBooking && (
              <>
                <POSButtons style={styles.calendarView} />
                {isBookingSelected && (
                  <View style={styles.bookingView}>
                    {isMobile ? (
                      <Title22 isBlack style={styles.title}>
                        {I18n.t("restaurantDetail.menuTitle")}
                      </Title22>
                    ) : (
                      <Title36>{I18n.t("restaurantDetail.menuTitle")}</Title36>
                    )}
                    {!isFullBookingCartAvailable && (
                      <CalendarDays
                        style={styles.calendarView}
                        isModify={isModify}
                        userLanguage={userLanguage}
                        calendarFlow={CalendarFlow.BOOKING}
                      />
                    )}
                    <BookingTemplate
                      navigation={navigation}
                      route={route}
                      isModify={isModify}
                    />
                  </View>
                )}
              </>
            )}
            {(!isBookingSelected || hideMenuBooking) && (
              <View style={styles.bookingView}>
                <ClickAndCollectTemplate
                  navigation={navigation}
                  route={route}
                  isRefillFromCart={isRefillFromCart}
                  posId={id}
                />
              </View>
            )}
          </View>
        </ScreenWithFooter>
        {isMobile && isMiniCartAvailable && (
          <MiniCart
            navigation={navigation}
            isRefillFromCart={isRefillFromCart}
          />
        )}
        {isMobile && isFullCartAvailable && (
          <FullCart
            navigation={navigation}
            isRefillFromCart={isRefillFromCart}
            route={route}
          />
        )}
        {isMobile && isMiniBookingCartAvailable && (
          <MiniBookingCart navigation={navigation} />
        )}
        {isMobile && isFullBookingCartAvailable && (
          <FullBookingCart navigation={navigation} route={route} />
        )}
        <OutOfStockModal />
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: isMobile ? 0 : 150,
      marginBottom: 50,
      backgroundColor: Colors.white,
    },
    calendarView: {
      marginVertical: Spacing.XL,
      alignItems: "flex-start",
      paddingLeft: isMobile ? 18 : 0,
    },
    title: {
      paddingLeft: isMobile ? 18 : 0,
    },
    bookingView: {
      backgroundColor: Colors.background1,
      paddingHorizontal: 150,
      marginHorizontal: -150,
      alignItems: "flex-start",
      paddingTop: 7,
    },
    loadingStyle: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
  });
