import React, { useState } from "react";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { Title14, Title16 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { IPosMenuElement, OrderItem } from "@foodi/core";
import {
  FastImage,
  ProductQuantity,
  ProductItemModal,
  IImportationTypes,
} from "@atomic";
import { IOfferItem, OfferViewModel } from "@modules";
import { Container } from "@assets";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { get, capitalize } from "lodash";

interface IProps {
  item: IOfferItem;
  selectedOfferId?: string;
  forwardTestID?: string;
  style?: ViewStyle;
  hasQuantity?: boolean;
  updateOrders?: Function;
  outOfStockItem?: boolean;
  sameProductPerOrder?: number;
  isOverProductsLimit?: boolean;
  lastProduct?: boolean;
  orderItem?: OrderItem;
  menuType?: IImportationTypes;
}

export const ProductItem: React.FC<IProps> = React.memo(
  ({
    item,
    forwardTestID,
    style,
    hasQuantity = true,
    selectedOfferId,
    updateOrders,
    outOfStockItem = false,
    sameProductPerOrder,
    isOverProductsLimit,
    lastProduct,
    orderItem,
    menuType,
  }) => {
    const {
      id,
      inheritedLabel,
      inheritedImage,
      inheritedPrice,
      inheritedFamily,
      container,
      baking,
      quantityOverall,
    } = item;
    const isMenu = menuType !== undefined;
    const offer = useSelector(
      (state: GlobalState) => state.offers.activeOrderableOffer
    );
    const menuElements = offer?.menus ? offer.menus[0]?.elements : null;
    const itemMenu: any = isMenu
      ? item
      : menuElements
      ? menuElements.find(
          (element) =>
            element?.idElement === item.localArticle.article.idElement
        )
      : ({} as IPosMenuElement);
    const allergens: [string] = itemMenu?.allergens || [];
    const certifications = itemMenu?.certifications || [];
    const [offerVM] = useState(new OfferViewModel());
    const [isMobile] = useDevices();

    const styles = _styles(outOfStockItem, isMobile, lastProduct);

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const totalQuantity = useSelector((state: GlobalState) => {
      return state.offers.orderItems
        .filter((item) => {
          return item.id === id;
        })
        .map((item) => item.quantity)
        .reduce((prev, curr) => prev + curr, 0);
    });

    const orderItems = useSelector((state: GlobalState) => {
      return state.offers.orderItems.filter((item) => {
        return item.id === id;
      });
    });
    const productTransparencyPreferences = isMenu
      ? useSelector((state: GlobalState) => {
          return state.holdingSetting.productTransparencyPreferences;
        })
      : useSelector((state: GlobalState) => {
          return state.holdingSetting.offersProductTransparencyPreferences;
        });

    const {
      showLabels = false,
      showIngredients = false,
      showPhoto = false,
      showPrice = false,
      showDescription = false,
    } = productTransparencyPreferences ?? {};

    const handleOpenModal = () => {
      setIsOpen(true);
    };

    const handleCloseModal = () => {
      setIsOpen(false);
    };

    const onRemoveFromCartWithBaking = () => {
      let lastItem = orderItems.slice(-1).pop();
      const orderItem: OrderItem = {
        __typename: "OrderItem",
        id: id,
        quantity: (lastItem?.quantity ?? 1) - 1,
        chosenBaking: lastItem?.chosenBaking ?? null,
        labelWhenAdded: inheritedLabel,
        priceWhenAdded: {
          amount: inheritedPrice.amount,
          currency: inheritedPrice.currency,
        },
        containerLabelWhenAdded: container?.label ? container.label : null,
        containerPriceWhenAdded: {
          amount: container?.price?.amount ? container.price.amount : "",
          currency: inheritedPrice.currency,
        },
        offerItem: {
          __typename: "OfferItem",
          id: selectedOfferId || "id",
          inheritedFamily: inheritedFamily,
        },
      };
      updateOrders?.(orderItem);
      handleCloseModal();
    };

    const onControlQuantity = (quantity: number) => {
      const orderItem: OrderItem = {
        __typename: "OrderItem",
        id: id,
        quantity: quantity,
        chosenBaking: null,
        labelWhenAdded: inheritedLabel,
        priceWhenAdded: {
          amount: inheritedPrice.amount,
          currency: inheritedPrice.currency,
        },
        containerLabelWhenAdded: container?.label ? container.label : null,
        containerPriceWhenAdded: {
          amount: container?.price?.amount ? container.price.amount : "",
          currency: inheritedPrice.currency,
        },
        offerItem: {
          __typename: "OfferItem",
          id: selectedOfferId || "id",
          inheritedFamily: inheritedFamily,
        },
      };
      updateOrders?.(orderItem);
    };

    const price = inheritedPrice?.amount ?? get(item, "price.amount");
    const description = offerVM.getOfferDescription(item, menuType);
    const showAllergens =
      productTransparencyPreferences?.showAllergens &&
      allergens &&
      allergens?.length > 0;
    const hasContent =
      ((showAllergens &&
        allergens &&
        allergens?.filter((allergen) => allergen !== "ALLERGEN_FREE").length >
          0) ||
        (showLabels && certifications && certifications.length > 0) ||
        (showDescription && description) ||
        (showIngredients && item.products && item.products?.length > 1)) &&
      (showLabels || showIngredients || showAllergens || showDescription);

    return (
      <>
        <Pressable onPress={hasContent ? handleOpenModal : null}>
          <View testID={forwardTestID} style={[styles.container, style]}>
            <View style={styles.innerContainer}>
              <View style={styles.upperDiv}>
                <View style={styles.leftDiv}>
                  <View style={styles.titleIconContainer}>
                    <Title16 style={styles.title}>
                      {capitalize(inheritedLabel)}
                      {!!container && (
                        <View style={styles.containerIcon}>
                          <Title16 style={styles.title}> + </Title16>
                          <Container />
                        </View>
                      )}
                    </Title16>
                  </View>

                  {showDescription && !!description && (
                    <Title16
                      style={[
                        styles.subTitle,
                        !inheritedImage && styles.marginBottomDiv,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      {description}.
                    </Title16>
                  )}
                </View>

                {showPhoto && !!inheritedImage && (
                  <View style={styles.rightDiv}>
                    <FastImage
                      imagePath={inheritedImage}
                      width={80}
                      style={styles.image}
                    />
                  </View>
                )}
              </View>
              <View style={styles.footer}>
                {showPrice && !!price && (
                  <View style={styles.currencyContainer}>
                    <Title16 style={styles.amount}>
                      {offerVM.getProductAmount(String(price))}€
                    </Title16>
                    {container?.price?.amount && (
                      <Title16 style={[styles.amount, styles.consignCurrency]}>
                        + {offerVM.getProductAmount(container?.price?.amount)}€{" "}
                        {I18n.t("restaurantDetail.container.name")}
                      </Title16>
                    )}
                  </View>
                )}

                {outOfStockItem ? (
                  <View style={styles.soldOutContainer}>
                    <View style={styles.soldOut}>
                      <Title14>{I18n.t("restaurantDetail.soldOut")}</Title14>
                    </View>
                  </View>
                ) : (
                  hasQuantity && (
                    <ProductQuantity
                      isOverProductsLimit={isOverProductsLimit}
                      sameProductPerOrder={sameProductPerOrder}
                      quantityOverall={quantityOverall}
                      onHandle={
                        !!baking
                          ? onRemoveFromCartWithBaking
                          : onControlQuantity
                      }
                      productId={id}
                      quantityValue={
                        !!baking ? totalQuantity : orderItem?.quantity
                      }
                      addQuantityOverride={
                        !!baking ? handleOpenModal : undefined
                      }
                      style={styles.productQuantityContainer}
                    />
                  )
                )}
              </View>
            </View>
          </View>
        </Pressable>

        <ProductItemModal
          item={item}
          forwardTestID={`${forwardTestID}-in-modal`}
          selectedOfferId={selectedOfferId}
          updateOrders={updateOrders}
          sameProductPerOrder={sameProductPerOrder}
          isOverProductsLimit={isOverProductsLimit}
          handleClose={handleCloseModal}
          isOpen={isOpen}
          productTransparencyPreferences={productTransparencyPreferences}
          menuType={menuType}
        />
      </>
    );
  }
);

const _styles = (
  outOfStockItem?: boolean,
  isMobile?: boolean,
  lastProduct?: boolean
) =>
  StyleSheet.create({
    container: {
      width: isMobile ? "100vw" : 409,
      alignItems: "center",
      flex: 1,
      backgroundColor: outOfStockItem
        ? Colors.disabledBackground
        : Colors.white,
    },
    innerContainer: {
      width: isMobile ? "95%" : "100%",
      height: "100%",
      borderRadius: isMobile ? 0 : 8,
      backgroundColor: outOfStockItem
        ? Colors.disabledBackground
        : Colors.white,
      padding: Spacing.M,
      borderBottomColor: "#DDDDDD",
      borderBottomWidth: isMobile ? (lastProduct ? 0 : 1) : 0,
    },
    upperDiv: {
      flexDirection: "row",
      flex: 1,
    },
    footer: {
      width: "100%",
      alignSelf: "flex-end",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    leftDiv: {
      flex: 1,
      marginRight: Spacing.S,
    },
    marginBottomDiv: {
      marginBottom: Spacing.S,
    },
    title: {
      color: Colors.darkGray,
      fontFamily: "manrope-bold",
      overflow: "hidden",
    },
    subTitle: {
      marginTop: 2,
      color: Colors.gray1,
    },
    image: {
      marginTop: Spacing.XS,
      height: 80,
      width: 80,
      borderRadius: 4,
    },
    rightDiv: {
      flex: 0,
      marginLeft: 100,
      alignItems: "flex-end",
    },
    amount: {
      marginTop: Spacing.L,
      color: Colors.darkGray,
    },
    soldOutContainer: {
      alignItems: "center",
      width: 80,
      marginLeft: "auto",
      marginTop: Spacing.L,
    },
    soldOut: {
      paddingHorizontal: 10,
      paddingVertical: 6,
      backgroundColor: Colors.footerBackgroundGray,
      borderRadius: 11.5,
    },
    titleIconContainer: {
      flexDirection: "row",
      alignItems: "flex-end",
    },
    currencyContainer: {
      marginTop: Spacing.S,
      flexDirection: "row",
    },
    consignCurrency: {
      paddingLeft: Spacing.S,
    },
    containerIcon: {
      flexDirection: "row",
      alignItems: "center",
    },
    productQuantityContainer: {
      marginTop: Spacing.L,
      marginLeft: "auto",
    },
  });
