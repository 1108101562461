import { Colors } from "@constants";
import style from "styled-components/native";

export const Wrapper = style.View`
    width: 100%;
    height: 100%;
    background-color: ${Colors.foodiBlackOpacity(0.5)};
    position: absolute;
`;

export const Container = style.View`
    width: 414px;
    border-radius: 8px;
    background-color: ${Colors.white};
    padding: 40px 60px 40px 48px;
    position: absolute;
    top: 27%;
    left: calc((100% - 414px) / 2);
`;

export const ContentContainer = style.View`
    align-items: center;
    justify-content: center;
`;

export const IconContainer = style.View`
    margin-right: 8px;
    margin-top: 5px;
`;

export const ListContainer = style.View`
    align-self: flex-start;
    margin-bottom: 30px;
    width: 306px;
`;

export const TextContainer = style.View`
    align-items: center;
`;

export const ButtonContainer = style.View`
    margin-left: -8px;
`;

export const Row = style.View`
    flex-direction: row;
`;

export const Title = style.Text`
    width: 306px;
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 30px;
`;

export const ItemText = style.Text`
    color: ${Colors.redError};
    font-family: manrope;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 10px;
`;
