import { RestaurantCard } from "@atomic";
import { INode, IPos } from "@foodi/core";
import { NavigationProp } from "@react-navigation/native";
import { TestIDs } from "@utils";
import React from "react";
import { FlatList } from "react-native";
import { useDevices } from "@hooks";
import { ItemContainer } from "./RestaurantCardsList.style";

interface IProps {
  restaurantCardsList?: INode<IPos>[];
  navigation?: NavigationProp<any>;
  currentTab?: number;
}

const RESTAURANT_CARD_LIST_COLUMNS = 3;

export const RestaurantCardsList: React.FC<IProps> = React.memo(
  ({ restaurantCardsList, navigation, currentTab }) => {
    const [isMobile] = useDevices();

    const renderItem = ({ item, index }: any) => {
      const hasGap = (index + 1) % RESTAURANT_CARD_LIST_COLUMNS !== 0;
      return (
        <ItemContainer key={`${index}_${item?.node?.name}_${item?.node?.id}`} hasGap={hasGap} isMobile={isMobile}>
          <RestaurantCard
            restaurantCard={item.node}
            navigation={navigation}
            currentTab={currentTab}
          />
        </ItemContainer>
      );
    };

    return (
      <FlatList
        testID={TestIDs.home.restaurantCard.views.restaurantCardListContainer}
        data={restaurantCardsList}
        key={isMobile ? `mobile_${JSON.stringify(restaurantCardsList)}` : `desktop_${JSON.stringify(restaurantCardsList)}`}
        renderItem={renderItem}
        keyExtractor={(item: INode<IPos>, index: number) => `${index}_${item?.node?.id}_${item?.node?.name}`}
        numColumns={!isMobile ? RESTAURANT_CARD_LIST_COLUMNS : 1}
      />
    );
  }
);
