"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FHoldingRepository = void 0;
const __1 = require("..");
const __2 = require("../../..");
const __3 = require("../../..");
const domain_1 = require("../../../domain");
const mappers_1 = require("../mappers");
class FHoldingRepository {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    getServices(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const servicesMapper = new mappers_1.ServicesMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __3.GET_SERVICES,
                });
                if (callback)
                    callback();
                return Promise.resolve(servicesMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.HoldingRepositoryErrors.GetServicesRequestError("GET_SERVICES_REQUEST_FAILED", error));
            }
        });
    }
    getHoldingWithRecipeStatus(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const holdingWithIsRecipeEnabledMapper = new mappers_1.HoldingWithIsRecipeEnabledMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __3.GET_HOLDING_WITH_IS_RECIPE_ENABLED,
                });
                if (callback)
                    callback();
                return Promise.resolve(holdingWithIsRecipeEnabledMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.HoldingRepositoryErrors.GetHoldingWithRecipeStatusRequestError("GET_HOLDING_WITH_RECIPE_STATUS_REQUEST_FAILED", error));
            }
        });
    }
    getNearHoldings(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const getNearHoldingsMapper = new mappers_1.GetNearHoldingsMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __2.GET_NEAR_HOLDINGS,
                    variables,
                    fetchPolicy: "network-only",
                });
                return Promise.resolve(getNearHoldingsMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.HoldingRepositoryErrors.GetNearHoldingsRequestError("GET_NEAR_HOLDINGS_REQUEST_FAILED", error));
            }
        });
    }
    addUserHoldingView(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const addUserHoldingViewMapper = new mappers_1.AddUserHoldingViewMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.ADD_USER_HOLDING_VIEW,
                    refetchQueries: ["getUserHoldingView"],
                    variables: { input },
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(addUserHoldingViewMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.HoldingRepositoryErrors.AddUserHoldingViewRequestError("ADD_USER_HOLDING_VIEW_REQUEST_FAILED", { error }));
            }
        });
    }
    switchUserHoldingView(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const switchUserHoldingViewMapper = new mappers_1.SwitchUserHoldingViewMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.SWITCH_USER_HOLDING_VIEW,
                    variables: { input },
                });
                return Promise.resolve(switchUserHoldingViewMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.HoldingRepositoryErrors.SwitchUserHoldingViewRequestError("SWITCH_USER_HOLDING_VIEW_REQUEST_FAILED", { error }));
            }
        });
    }
    getUserHoldingView(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const userHoldingViewMapper = new mappers_1.UserHoldingViewMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.GET_USER_HOLDING_VIEW,
                    variables: input,
                });
                return Promise.resolve(userHoldingViewMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.HoldingRepositoryErrors.GetUserHoldingViewRequestError("GET_USER_HOLDING_VIEW_REQUEST_FAILED", { error }));
            }
        });
    }
    migrateToHoldingView(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const migrateToHoldingViewMapper = new mappers_1.MigrateToHoldingViewMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.MIGRATE_TO_HOLDING_VIEW,
                    variables: input,
                });
                return Promise.resolve(migrateToHoldingViewMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.HoldingRepositoryErrors.MigrateToHoldingViewRequestError("MIGRATE_TO_HOLDING_VIEW_REQUEST_FAILED", { error }));
            }
        });
    }
    deleteUserHoldingView(input) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.DELETE_USER_HOLDING_VIEW,
                    variables: input,
                });
                return Promise.resolve(response);
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.HoldingRepositoryErrors.DeleteUserHoldingViewRequestError("DELETE_USER_HOLDING_VIEW_REQUEST_FAILED", { error }));
            }
        });
    }
    updateUserHoldingView(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const updateUserHoldingViewMapper = new mappers_1.UpdateUserHoldingViewMapper();
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.UPDATE_USER_HOLDING_VIEW,
                    variables: { input },
                });
                return Promise.resolve(updateUserHoldingViewMapper.toDomain(response));
            }
            catch (error) {
                //@ts-ignore
                return Promise.reject(new domain_1.HoldingRepositoryErrors.UpdateUserHoldingViewRequestError("UPDATE_USER_HOLDING_VIEW_REQUEST_FAILED", { error }));
            }
        });
    }
}
exports.FHoldingRepository = FHoldingRepository;
