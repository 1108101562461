import React, { useEffect } from "react";
import { HomeNavigation, MaintenanceNavigation } from "@modules";
import { GlobalState, NavigationActions, SplashScreenState } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { NavigationProp } from "@react-navigation/native";

interface IProps {
  navigation: NavigationProp<any>;
}

export const RootNavigator: React.FC<IProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const { isInitialLinkHandled, splashScreenState } = useSelector(
    (state: GlobalState) => state.navigation
  );
  const isMaintenanceModeActive = window.config.SITE_MAINTENANCE;

  useEffect(() => {
    // hook to be run once when the app start to check if a deeplink opened
    if (
      !isInitialLinkHandled &&
      splashScreenState === SplashScreenState.PLAYED
    ) {
      dispatch(NavigationActions.setIsInitialLinkHandled(true));
    }
  }, [splashScreenState, isInitialLinkHandled]);

  return isMaintenanceModeActive ? (
    <MaintenanceNavigation />
  ) : (
    <HomeNavigation />
  );
};
