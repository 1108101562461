"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryHoldingRepository = void 0;
const __1 = require("..");
const domain_1 = require("../../../domain");
class InMemoryHoldingRepository {
    constructor(holdingFixtures, hasFixture) {
        this.holdingFixtures = holdingFixtures;
        this.hasFixture = hasFixture;
    }
    /* istanbul ignore next */
    updateUserHoldingView(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    deleteUserHoldingView(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    migrateToHoldingView(request) {
        throw new Error("Method not implemented.");
    }
    getUserHoldingView(request) {
        if (this.hasFixture)
            return Promise.resolve(__1.userHoldingViewFixtures);
        return Promise.reject(new domain_1.HoldingRepositoryErrors.GetUserHoldingViewRequestError("GET_USER_HOLDING_VIEW_REQUEST_FAILED"));
    }
    /* istanbul ignore next */
    addUserHoldingView(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    switchUserHoldingView(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getServices(callback) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getHoldingWithRecipeStatus(callback) {
        throw new Error("Method not implemented.");
    }
    getNearHoldings({ searchText, }) {
        if (this.holdingFixtures) {
            return Promise.resolve(Object.assign(Object.assign({}, this.holdingFixtures), { holdingInfo: this.holdingFixtures.holdingInfo.filter((holding) => {
                    var _a;
                    const holdingName = `${(_a = holding.name) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()}`;
                    const _searchText = `${searchText.toLocaleLowerCase()}`;
                    return holdingName.includes(_searchText);
                }) }));
        }
        return Promise.reject(new domain_1.HoldingRepositoryErrors.GetNearHoldingsRequestError("NEAR_HOLDINGS_REQUEST_FAILED"));
    }
}
exports.InMemoryHoldingRepository = InMemoryHoldingRepository;
