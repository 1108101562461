"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ALL_ORDERS = void 0;
const client_1 = require("@apollo/client");
exports.GET_ALL_ORDERS = client_1.gql `
fragment getAllOrdersFragment on EntityConnection {
  edges {
    node {
      ... on Order {
        id
        state
        idWithdrawal
        created
        updated
        offerTemplateName
        withdrawRange
        cancelableUntil
        paymentMethod
        totalPrice {
          amount
          currency
        }
        idHuman
        orderRating {
          id
          ratings {
            mealRating
            presentationRating
            serviceRating
            globalRating
          }
          created
        }
        offer (querySearch: [{key: "withInactive", value: "true"}]){
          id
          published
          withdrawRange
          offerTemplate_v2 (querySearch: [{key: "withInactive", value: "true"}])
          {
            ... on Entity {
              id
            }
            withdrawalType
            pos {
              id
              name
            }
            type
          }
        }
      }
    }
  }
}

query getAllOrders(
  $idGuest: String!
  $fromDate: String!
  $offerTemplateTypes: String!
  $withdrawalTypes: String!
) {
  allOrders: list(
    type: "Order"
    order: "-id"
    querySearch: [
      { key: "idGuest", value: $idGuest }
      { key: "updated", operator: ">", value: $fromDate } 
      { key: "offerTemplateTypes", value: $offerTemplateTypes }
      { key: "state", operator: "*", value: "ACCEPTED,PREPARING,READY,CANCELLED,COMPLETED" } 
      { key: "withInactive", value: "true" }
      {
        key: "offerTemplate.withdrawalType"
        operator: "*"
        value: $withdrawalTypes
      }
    ]
  ) {
    ...getAllOrdersFragment
  }
}
`;
