import React, { useMemo } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Title16, Title13, Title18, Title22, Title30 } from "@stylesheets";
import { Colors } from "@constants";
import { I18n } from "react-redux-i18n";
import { CircleAdd, Information } from "@assets";
import { useDispatch, useSelector } from "react-redux";
import { ForceInfoAppUpdateActions, GlobalState, LoaderActions } from "@redux";
import { HoldingView, Token, UserProfile } from "@foodi/core";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { HoldingThunks, HoldingViewModel } from "@modules";
import _ from "lodash";
import {
  HoldingViewDeleteModal,
  HoldingViewModal,
  HoldingViewRow,
  ModalTemplate,
} from "@atomic";
import { NavigationProp } from "@react-navigation/native";
import { getApolloClient } from "../../../apollo";

interface IProps {
  navigation: NavigationProp<any>;
}

export const HoldingViewList: React.FC<IProps> = React.memo(
  ({ navigation }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const [holdingViews, setHoldingViews] = React.useState<HoldingView[]>([]);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
    const [currentHoldingView, setCurrentHoldingView] = React.useState<
      HoldingView | undefined
    >();
    const [nextHoldingView, setNextHoldingView] = React.useState<
      HoldingView | undefined
    >();
    const [updateHoldingViews, setUpdateHoldingViews] = React.useState<boolean>(
      false
    );

    const Title: React.FC<any> = isMobile ? Title22 : Title30;
    const Info: React.FC<any> = isMobile ? Title18 : Title22;
    const HoldingViewRowText: React.FC<any> = isMobile ? Title13 : Title18;

    const { idHoldingView } =
      useSelector((state: GlobalState) => state.auth?.userInfo) ??
      ({} as UserProfile);

    const { accessToken } =
      useSelector((state: GlobalState) => state.auth?.authToken) ??
      ({} as Token);

    const holdingViewModel = new HoldingViewModel(dispatch, isMobile);

    const setSortedHoldingViews = async () => {
      dispatch(LoaderActions.setLoading(true));
      const sortedHoldingViews = await holdingViewModel.getSortedHoldingViews(
        accessToken,
        idHoldingView
      );
      setHoldingViews(sortedHoldingViews);
      if (sortedHoldingViews.length === 1)
        checkDefaultHolding(sortedHoldingViews);
      dispatch(LoaderActions.setLoading(false));
    };

    React.useEffect(() => {
      if (accessToken) setSortedHoldingViews();
    }, [updateHoldingViews, idHoldingView]);

    const handleAddHoldingView = () => {
      setCurrentHoldingView(undefined);
      setDeleteModal(false);
      setIsOpen(true);
    };

    const checkDefaultHolding = (sortedHoldingViews: HoldingView[]) => {
      const isActiveHoldingView = holdingViewModel.isActiveHoldingView(
        idHoldingView,
        sortedHoldingViews[0]?.id
      );
      if (!isActiveHoldingView)
        handleHoldingViewSwitch(sortedHoldingViews[0])();
    };

    const handleClose = (update?: boolean, nextHV?: HoldingView) =>
      /* istanbul ignore next */
      async () => {
        if (nextHV) await handleHoldingViewSwitch(nextHV)();
        if (update) {
          await refreshAppInfp();
          setUpdateHoldingViews(!updateHoldingViews);
        }
        setIsOpen(false);
      };

    const handleHoldingViewSwitch = (hv: HoldingView) =>
      /* istanbul ignore next */
      async () => {
        const result = await dispatch(
          HoldingThunks.switchUserHoldingView({
            idHolding: hv?.holding?.id,
            guestLastName: hv?.guest?.lastName,
            serialNumber:
              hv?.guest?.serialNumber || hv?.guest?.supportSerialNumber,
            idHoldingView: hv.id,
          })
        );
        if (nextHoldingView) setNextHoldingView(undefined);
        //@ts-ignore
        if (result) {
          refreshAppInfp();
        }
      };

    const refreshAppInfp = async () => {
      dispatch(LoaderActions.setLoading(true));
      await getApolloClient().cache.reset();
      dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(true));
    };

    const handleModifyHoldingView = (hv: HoldingView) =>
      /* istanbul ignore next */
      () => {
        setCurrentHoldingView(hv);
        setDeleteModal(false);
        setIsOpen(true);
      };

    const handleDeleteHoldingView = (
      hv: HoldingView,
      index: number,
      isActiveHoldingView?: boolean
    ) =>
      /* istanbul ignore next */
      () => {
        if (holdingViews?.[index] && isActiveHoldingView)
          setNextHoldingView(holdingViews?.[index]);
        setCurrentHoldingView(hv);
        setDeleteModal(true);
        setIsOpen(true);
      };

    const handleModalClose = (nextHV?: HoldingView) =>
      /* istanbul ignore next */
      (update?: boolean) => handleClose(update, nextHV)();

    return (
      <>
        <View style={styles.container}>
          <Title isBlack isBold>
            {I18n.t("profile.holdingAndBadge")}
          </Title>
          <Title16>{I18n.t("profile.holdingViewDescription")}</Title16>
          <View style={styles.holdingViewsContainer}>
            {_.isEmpty(holdingViews) ? (
              <View style={styles.emptyHoldingViews}>
                <HoldingViewRowText style={styles.holdingView} isBold bold>
                  {holdingViewModel.getHoldingViewRowText()}
                </HoldingViewRowText>
              </View>
            ) : (
              <>
                {holdingViews.map((hv: HoldingView, index: number) => (
                  <HoldingViewRow
                    navigation={navigation}
                    hv={hv}
                    index={index}
                    handleModifyHoldingView={handleModifyHoldingView}
                    handleDeleteHoldingView={handleDeleteHoldingView}
                    handleHoldingViewSwitch={handleHoldingViewSwitch}
                  />
                ))}
                <View style={[styles.row, styles.marginBottom]}>
                  <View style={styles.iconContainer}>
                    <Information />
                  </View>
                  <Title16>{I18n.t("account.holdingViewInformation")}</Title16>
                </View>
              </>
            )}
          </View>
          <View style={styles.row}>
            <Pressable
              testID={TestIDs.profile.actions.pressAddHoldingView}
              style={styles.iconContainer}
              onPress={handleAddHoldingView}
            >
              <CircleAdd />
            </Pressable>
            <Info isBlack isNotBold>
              {I18n.t("account.add")}
            </Info>
          </View>
        </View>
        <ModalTemplate
          forwardCloseTestID={TestIDs.profile.actions.pressholdingViewModal}
          isOpen={isOpen}
          handleClose={handleClose(false, undefined)}
          isMobile={isMobile}
          isFullScreen={!deleteModal}
          closeOnClickOutside
          isCenter
        >
          {deleteModal ? (
            <HoldingViewDeleteModal
              handleClose={handleModalClose(nextHoldingView)}
              currentHoldingView={currentHoldingView}
            />
          ) : (
            <HoldingViewModal
              handleClose={handleModalClose(undefined)}
              currentHoldingView={currentHoldingView}
            />
          )}
        </ModalTemplate>
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      borderWidth: isMobile ? 0 : 1,
      borderRadius: 8,
      borderColor: Colors.darkGrey,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingHorizontal: isMobile ? 0 : 40,
      paddingVertical: isMobile ? 0 : 20,
      marginBottom: isMobile ? 0 : 20,
    },
    holdingViewsContainer: {
      marginTop: 17,
    },
    holdingView: {
      backgroundColor: Colors.disabledBackground,
      borderRadius: 15,
      paddingHorizontal: 8,
      paddingVertical: 2,
      borderWidth: 2,
      borderColor: Colors.transparent,
      flexDirection: "row",
    },
    row: {
      flexDirection: "row",
    },
    iconContainer: {
      marginRight: 10,
    },
    marginBottom: {
      marginBottom: 16,
    },
    emptyHoldingViews: {
      marginBottom: 20,
    },
  });
