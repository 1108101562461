import { combineReducers } from "redux";
import { AuthReducer as auth, AuthState } from "../modules/auth/ducks";
import {
  HeaderState,
  NavigationReducer as navigation,
  NavigationState,
} from "./ducks";
import { i18nReducer } from "react-redux-i18n";
import {
  ConfirmationPageState,
  ConfirmationPageReducer as confirmationPage,
  TransactionsReducer as transactions,
  HistoricalReducer as historical,
  TransactionsState,
  ArticleReducer as articlePage,
  ArticleState,
  HistoricalState,
} from "@modules";
import { AccountReducer as account, AccountState } from "@modules";
import { PointOfSaleReducer as pointOfSale, PointOfSaleState } from "@modules";
import { OffersReducer as offers, OffersState } from "@modules";
import {
  BookingReducer as booking,
  BookingState,
  PaymentsReducer as payments,
  PaymentsState,
  TableReducer as table,
  TableState,
  MarketingCardReducer as marketingCard,
  MarketingCardState,
  HoldingSettingState,
  HoldingSettingReducer as holdingSetting,
} from "@modules";
import { MobileStateReducer as mobileState, MobileSizeState } from "../redux";
import { BrandThemeReducer as brandTheme, BrandThemeState } from "../redux";
import { LoaderReducer as loader, LoaderState } from "./ducks/loader";
import {
  ForceInfoAppUpdateReducer as forceInfoAppUpdate,
  ForceInfoAppUpdateState,
} from "./ducks/forceInfoAppUpdate";
import {
  GetAppMobileReducer as getAppMobile,
  GetAppMobileState,
} from "./ducks/getAppMobile";
import { ModalReducer as modal, ModalState } from "./ducks/modal";
import header from "./ducks/header";

export interface GlobalState {
  i18n: any;
  auth: AuthState;
  navigation: NavigationState;
  transactions: TransactionsState;
  historical: HistoricalState;
  account: AccountState;
  mobileState: MobileSizeState;
  brandTheme: BrandThemeState;
  pointOfSale: PointOfSaleState;
  offers: OffersState;
  loader: LoaderState;
  modal: ModalState;
  booking: BookingState;
  getAppMobile: GetAppMobileState;
  payments: PaymentsState;
  header: HeaderState;
  confirmationPage: ConfirmationPageState;
  articlePage: ArticleState;
  forceInfoAppUpdate: ForceInfoAppUpdateState;
  table: TableState;
  marketingCard: MarketingCardState;
  holdingSetting: HoldingSettingState
}

export const rootReducer = combineReducers<GlobalState>({
  i18n: i18nReducer,
  navigation,
  auth,
  transactions,
  historical,
  account,
  mobileState,
  brandTheme,
  pointOfSale,
  offers,
  loader,
  modal,
  booking,
  getAppMobile,
  payments,
  confirmationPage,
  articlePage,
  header,
  forceInfoAppUpdate,
  table,
  marketingCard,
  holdingSetting,
});

export type StoreState = ReturnType<typeof rootReducer>;
