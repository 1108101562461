//import CodePush from 'react-native-code-push';

import { IConfig } from "./type";

const Config = window.config;
const appConfiguration: IConfig = {
  API_GRAPHQL_ENDPOINT: Config.API_GRAPHQL_ENDPOINT,
  AUTH0: {
    APP: {
      AUDIENCE: Config.AUTH0_APP_AUDIENCE,
      CLIENT_ID: Config.AUTH0_APP_CLIENT_ID,
      DOMAIN: Config.AUTH0_APP_DOMAIN,
      EMAIL_VERIFICATION_KEY: Config.AUTH0_APP_EMAIL_VERIFICATION_KEY,
      PROMPT: Config.AUTH0_APP_PROMPT,
      SCOPE: Config.AUTH0_APP_SCOPE,
      USER_ID_KEY: Config.AUTH0_APP_USER_ID_KEY,
    },
    WEB: {
      AUDIENCE: Config.AUTH0_WEB_AUDIENCE,
      CLIENT_ID: Config.AUTH0_WEB_CLIENT_ID,
      DOMAIN: Config.AUTH0_WEB_DOMAIN,
      EMAIL_VERIFICATION_KEY: Config.AUTH0_WEB_EMAIL_VERIFICATION_KEY,
      PROMPT: Config.AUTH0_WEB_PROMPT,
      RESPONSE_TYPE: Config.AUTH0_WEB_RESPONSE_TYPE,
      USER_ID_KEY: Config.AUTH0_WEB_USER_ID_KEY,
    },
  },
  BRANCH_IO: {
    BRANCH_IO_KEY: Config.BRANCH_IO_TEST_MODE
      ? Config.BRANCH_IO_TEST_KEY
      : Config.BRANCH_IO_PRODUCTION_KEY,
    BRANCH_IO_LINK_DOMAIN: Config.BRANCH_IO_LINK_DOMAIN,
    BRANCH_IO_TEST_MODE: Config.BRANCH_IO_TEST_MODE,
  },
  CLOUDIMAGE_BASE_URL: Config.CLOUDIMAGE_BASE_URL,
  CODEPUSH: {
    checkFrequency: "", //CodePush.CheckFrequency.ON_APP_RESUME,
  },
  CONTEST: {
    BASE_URL: Config.CONTEST_BASE_URL,
    EXIT_PAGE: Config.CONTEST_EXIT_PAGE,
    SUCCESS_PAGE: Config.CONTEST_SUCCESS_PAGE,
    TOKEN_NAMESPACE: Config.CONTEST_TOKEN_NAMESPACE,
  },
  DISNEY_HOLDING_ID: "", //Config.DISNEY_HOLDING_ID,
  ENV: Config.ENV,
  INSTABUG_TOKEN: Config.INSTABUG_TOKEN,
  PAYMENT: {
    CLOSE_URL: Config.PAYMENT_CLOSE_URL,
  },
  PERSONAL_DATA_URL: Config.PERSONAL_DATA_URL,
  SENTRY: {
    ENVIRONMENT: Config.SENTRY_ENVIRONMENT,
    URL: Config.SENTRY_URL_DSN,
  },
  TOS_URL: Config.TOS_URL,
  WEB_BASE_URL: Config.WEB_BASE_URL,
  ZENDESK: {
    BASE_URL: Config.ZENDESK_BASE_URL,
    EXIT_URL: Config.ZENDESK_EXIT_URL,
  },
  SITE_MAINTENANCE: Config.SITE_MAINTENANCE,
};

export default appConfiguration;
