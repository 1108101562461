"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRANSACTIONS_FRAGMENT = void 0;
const client_1 = require("@apollo/client");
exports.TRANSACTIONS_FRAGMENT = client_1.gql `
  fragment transactionsFragment on TicketConnection {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date: validDate
        oldBalance {
          amount
          currency
        }
        newBalance {
          amount
          currency
        }
        virtual
        pos {
          id
          name
          type
        }
        total {
          amount
          currency
        }
      }
    }
  }
`;
