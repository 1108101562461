import React, { useState } from "react";
import { I18n } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";

import { Amex, CB, Edenred, Mastercard, Visa } from "@assets";
import { Icon, LanguageToolTip } from "@atomic";
import { Colors } from "@constants";
import { getLabelForLanguage, TestIDs } from "@utils";
import { GlobalState } from "@redux";

import {
  Wrapper,
  Container,
  FooterText,
  Gap,
  Separator,
  Row,
  IconAndTextContainer,
  IconText,
  Copyright,
  CopyrightText,
  Dot,
} from "./Footer.style";
import { useDevices } from "@hooks";
import { HomeScreens, ProfileScreens, ProfileTabs } from "@modules";
import { NavigationProp } from "@react-navigation/native";
import { ILanguage } from "@foodi/core";

interface IProps {
  navigation: NavigationProp<any>;
}

export const Footer: React.FC<IProps> = React.memo(({ navigation }) => {
  const [isMobile] = useDevices();
  const dispatch = useDispatch();
  const language = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language || state.i18n?.locale
  );

  const isLogin = useSelector((state: GlobalState) => state.auth?.isLogin);

  const handleAppleStore = () => {
    window
      .open(
        "https://apps.apple.com/fr/app/foodi/id1377579624?l=fr&ls=1",
        "_blank"
      )
      ?.focus();
  };

  const handleAndroidStore = () => {
    window
      .open(
        "https://play.google.com/store/apps/details?id=fr.compassgroup.foodi",
        "_blank"
      )
      ?.focus();
  };

  const handleNeedHelp = () => {
    window.open(window.config.ZENDESK_BASE_URL, "_blank")?.focus();
  };

  const handleLegalInformation = () => {
    if (isLogin) {
      navigation?.navigate(HomeScreens.PROFILE_STACK, {
        screen: ProfileScreens.PROFILE_SCREEN,
        params: {
          tab: ProfileTabs.LEGAL_INFORMATION,
          showLeftContainerFlag: null,
        },
      });
    }
  };

  const handleLanguagePage = () => {
      setShowLanguageToolTip(!showLanguageToolTip);
  };

  const handleAccountPage = () => {
    if (isLogin) {
      navigation?.navigate(HomeScreens.PROFILE_STACK, {
        screen: ProfileScreens.PROFILE_SCREEN,
        params: {
          tab: ProfileTabs.ACCOUNT,
          showLeftContainerFlag: null,
        },
      });
    }
  };

  const handlePersonalDataPolicy = () => {
    window.open(window.config.PUD, "_blank")?.focus();
  };

  const handleCookiesPolicy = () => {
    window.open(window.config.COOKIES_POLICIES, "_blank")?.focus();
  };

  /* istanbul ignore next */
  const handleCookiesParameters = () => {
    // this is required because we need to force the reload of one trust functions to be able to display the cookies preferences on click
    (window as any)?.reloadOTBanner?.();
  };

  const [showLanguageToolTip, setShowLanguageToolTip] = useState<boolean>(
    false
  );

  const [guestLanguage, setGuestLanguage] = useState<ILanguage>(
    language as ILanguage
  );

  return (
    <Wrapper
      testID={TestIDs.home.footer.views.footerContainer}
      isMobile={isMobile}
    >
      <Container isMobile={isMobile}>
        <Row isMobile={isMobile}>
          <Gap isMobile={isMobile}>
            <FooterText isMobile={isMobile}>
              {I18n.t("homepage.footer.securePayment")}
            </FooterText>
          </Gap>
          {/* <Gap isMobile={isMobile}>
            <Edenred />
          </Gap> */}
          <Gap isMobile={isMobile}>
            <CB />
          </Gap>
          <Gap isMobile={isMobile}>
            <Mastercard />
          </Gap>
          <Gap isMobile={isMobile}>
            <Visa />
          </Gap>
          <Gap isMobile={isMobile}>
            <Amex />
          </Gap>
        </Row>
        {!isMobile && <Separator />}
        <Row isMobile={isMobile}>
          <Gap isMobile={isMobile}>
            <FooterText isMobile={isMobile}>
              {I18n.t("homepage.footer.ourApps")}
            </FooterText>
          </Gap>
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.iosStore}
            onPress={handleAppleStore}
          >
            <Icon
              name="Apple_grey_24dp"
              color={Colors.footerAppsGray}
              size={24}
            />
            <IconText isMobile={isMobile}>iOS</IconText>
          </IconAndTextContainer>
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.androidStore}
            onPress={handleAndroidStore}
          >
            <Icon
              name="Android_grey_24dp"
              color={Colors.footerAppsGray}
              size={24}
            />
            <IconText isMobile={isMobile}>Android</IconText>
          </IconAndTextContainer>
        </Row>
      </Container>
      <Container isMobile={isMobile}>
        <Row isMobile={isMobile}>
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.helpPage}
            onPress={handleNeedHelp}
            isMobile={isMobile}
          >
            <FooterText isMobile={isMobile}>
              {I18n.t("homepage.footer.needHelp")} ?
            </FooterText>
          </IconAndTextContainer>
          <Gap isMobile={isMobile}>
            <Dot />
          </Gap>
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.languagePage}
            onPress={handleLanguagePage}
            isMobile={isMobile}
          >
            <Icon name="Language" color={Colors.footerGray} size={24} />
            <IconText isMobile={isMobile}>
              {getLabelForLanguage(language)}
            </IconText>
            {showLanguageToolTip && (
              <LanguageToolTip
              dismiss={setShowLanguageToolTip}
              language={guestLanguage}
              onLanguageChange={setGuestLanguage}
              />
            )}
          </IconAndTextContainer>
          <Gap isMobile={isMobile}>
            <Dot />
          </Gap>
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.accountPage}
            onPress={handleAccountPage}
            isMobile={isMobile}
          >
            <FooterText isMobile={isMobile}>
              {I18n.t("homepage.footer.myAccount")}
            </FooterText>
          </IconAndTextContainer>
        </Row>
      </Container>
      <Container isMobile={isMobile} marginBottom={25}>
        <Row isMobile={isMobile}>
          <Gap isMobile={isMobile}>
            <Row>
              <Copyright>
                <CopyrightText>c</CopyrightText>
              </Copyright>
              <FooterText isMobile={isMobile}>
                {" "}
                Compass Group France 2023
              </FooterText>
            </Row>
          </Gap>
          <Gap isMobile={isMobile}>
            <Dot />
          </Gap>
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.legalPage}
            onPress={handleLegalInformation}
            isMobile={isMobile}
            haveBreakLine
          >
            <FooterText isMobile={isMobile}>
              {`${I18n.t("homepage.footer.legal")}`}
            </FooterText>
          </IconAndTextContainer>
        </Row>
        <Row isMobile={isMobile}>
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.legalPage}
            onPress={handleLegalInformation}
            isMobile={isMobile}
          >
            <FooterText isMobile={isMobile}>
              {`${I18n.t("homepage.footer.information")}`}
            </FooterText>
          </IconAndTextContainer>
          <Gap isMobile={isMobile}>
            <Dot />
          </Gap>
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.personalDataPolicy}
            onPress={handlePersonalDataPolicy}
            isMobile={isMobile}
          >
            <FooterText isMobile={isMobile}>
              {I18n.t("homepage.footer.personalDataPolicy")}
            </FooterText>
          </IconAndTextContainer>
          <Gap isMobile={isMobile}>
            <Dot />
          </Gap>
        </Row>
        <Row isMobile={isMobile}>
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.cookiesPolicy}
            onPress={handleCookiesPolicy}
            isMobile={isMobile}
          >
            <FooterText isMobile={isMobile}>
              {I18n.t("homepage.footer.cookiesPolicy")}
            </FooterText>
          </IconAndTextContainer>
          <Gap isMobile={isMobile}>
            <Dot />
          </Gap>
          <div
            className="ot-sdk-show-settings-element"
            onMouseOver={handleCookiesParameters}
          >
            <IconAndTextContainer
              testID={TestIDs.home.footer.actions.cookiesParameters}
              isMobile={isMobile}
            >
              <FooterText isMobile={isMobile}>
                {I18n.t("homepage.footer.cookiesParameters")}
              </FooterText>
            </IconAndTextContainer>
          </div>
        </Row>
      </Container>
    </Wrapper>
  );
});
