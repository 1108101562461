import { PixelRatio } from "react-native";
import { I18n } from "react-redux-i18n";
import moment, { Moment } from "moment";
import { isEmpty } from "lodash";

import {
  INode,
  IPos,
  IPosSchedule,
  IPosStatus,
  OfferTemplateWithdrawalType,
} from "@foodi/core";
import { getCloudImageUrl } from "@utils";
import { ReactNode } from "react";
import { EatIn, SeeAll, TakeAway, Images } from "@assets";

interface IPosStatusSchedule {
  status: IPosStatus;
  schedules?: string[];
}

interface IRestaurantCardType {
  title: string;
  isSelected: boolean;
  type: TabsType;
  icon: ReactNode;
}

export enum PosType {
  SELF = "SELF",
  CAFETERIA = "CAFETERIA",
  BRASSERIE = "BRASSERIE",
  OTHER = "OTHER",
}

export enum AtSiteTakeAwayType {
  AT_SITE = "AT_SITE",
  TAKE_AWAY = "TAKE_AWAY",
  AT_SITE_TAKE_AWAY = "AT_SITE_TAKE_AWAY",
  NONE = "NONE",
}

export enum TabsType {
  SEE_ALL = "SEE_ALL",
  EAT_IN = "EAT_IN",
  TAKE_OUT = "TAKE_OUT",
}

export class RestaurantCardViewModel {
  private status: IPosStatus = "CLOSED";
  private schedules: string[] = [];
  private tabs: IRestaurantCardType[] = [
    {
      title: I18n.t("refill.seeAll"),
      isSelected: true,
      type: TabsType.SEE_ALL,
      icon: SeeAll,
    },
    {
      title: I18n.t("homepage.restaurantCard.eatIn"),
      isSelected: false,
      type: TabsType.EAT_IN,
      icon: EatIn,
    },
    {
      title: I18n.t("homepage.restaurantCard.takeOut"),
      isSelected: false,
      type: TabsType.TAKE_OUT,
      icon: TakeAway,
    },
  ];

  getInitTabs = (): IRestaurantCardType[] => {
    return this.tabs;
  };

  getTabs = (tabIndex: number): IRestaurantCardType[] => {
    return this.tabs.map((tab, index) => ({
      ...tab,
      isSelected: tabIndex === index,
    }));
  };

  getCards = (
    atSiteTakeAwayType: string[],
    withdrawalType: string[],
    restaurantCardsList?: INode<IPos>[]
  ) =>
    restaurantCardsList?.filter(
      ({ node }) =>
        atSiteTakeAwayType.includes(node.atSiteTakeAwayType) ||
        node.activeOfferTemplates?.some((offer) =>
          withdrawalType.includes(offer.withdrawalType)
        )
    );

  getCardsFiltered = (restaurantCardsList?: INode<IPos>[]) => {
    return restaurantCardsList
      ?.filter?.(
        (r: INode<IPos>) =>
          r?.node?.atSiteTakeAwayType !== AtSiteTakeAwayType.NONE
      );
  };

  getRestaurantCards = (
    tabIndex: number,
    restaurantCardsList?: INode<IPos>[]
  ) => {
    const tab = this.tabs[tabIndex].type;
    switch (tab) {
      case TabsType.SEE_ALL:
        return this.getCardsFiltered(restaurantCardsList);
      case TabsType.EAT_IN:
        return this.getCardsFiltered(
          this.getCards(
            [AtSiteTakeAwayType.AT_SITE, AtSiteTakeAwayType.AT_SITE_TAKE_AWAY],
            [
              OfferTemplateWithdrawalType.POS_AT_SITE,
              OfferTemplateWithdrawalType.POS_CLICK_SERVE,
              OfferTemplateWithdrawalType.TABLE_SERVICE,
            ],
            restaurantCardsList
          )
        );
      case TabsType.TAKE_OUT:
        return this.getCardsFiltered(
          this.getCards(
            [
              AtSiteTakeAwayType.TAKE_AWAY,
              AtSiteTakeAwayType.AT_SITE_TAKE_AWAY,
            ],
            [
              OfferTemplateWithdrawalType.CONNECTED_LOCKERS,
              OfferTemplateWithdrawalType.POS_TAKE_AWAY,
            ],
            restaurantCardsList
          )
        );
    }
  };

  getInfoMessage = (tabIndex: number) => {
    const tab = this.tabs[tabIndex]?.type;
    return I18n.t(
      `homepage.restaurantCard.${
        tab === TabsType.EAT_IN
          ? "orderNotAvailableEatIn"
          : "orderNotAvailableTakeAway"
      }`
    );
  };

  getStatus = (): boolean => this.status === "OPENED";

  getUri = (restaurantCard: IPos) =>
    !isEmpty(restaurantCard?.image)
      ? getCloudImageUrl(
          restaurantCard?.image,
          "height",
          String(PixelRatio.getPixelSizeForLayoutSize(648))
        )
      : Images.defaultPosImg;

  getService = (restaurantCard: IPos) =>
    I18n.t(
      restaurantCard?.bookingOfferTemplate?.length
        ? "homepage.restaurantCard.booking"
        : restaurantCard?.activeOfferTemplates?.length
        ? "homepage.restaurantCard.clickAndCollect"
        : ""
    );

  isBookingService = (restaurantCard: IPos) =>
    restaurantCard?.bookingOfferTemplate?.length;

  getOpenHours = (formattedHours: string[]) => {
    return !this.getStatus()
      ? this.getOpenedHours(formattedHours)
      : this.schedules;
  };

  getHoursToShow = (formattedHours: string[], openHours: string[]) =>
    formattedHours?.length > 1 ? `${openHours?.[0]}-${openHours?.[1]}` : null;

  getShowMore = (formattedHours: string[]): string =>
    formattedHours?.length > 2 ? " ..." : "";

  formatScheduleHours = (hours: string[]): string[] =>
    hours.map((hour: string) => moment(hour, "HH:mm:ss").format("H:mm"));

  getScheduleHoursOfDay = (
    schedules: IPosSchedule[],
    dayOfWeek: number = moment().isoWeekday()
  ): string[] => {
    for (const schedule of schedules) {
      if (schedule.days.includes(dayOfWeek)) {
        return schedule.hours;
      }
    }

    return [];
  };

  getOpenedHours = (formattedHours: string[]): string[] => {
    for (let i = 0; i < formattedHours.length; i = i + 2) {
      const start: Moment = moment(formattedHours[i], "HH:mm:ss");

      if (moment().isBefore(start)) {
        return [formattedHours[i], formattedHours[i + 1]];
      }
    }
    return [formattedHours[0], formattedHours[1]];
  };

  getPosStatusFromSchedules = (
    schedules: IPosSchedule[]
  ): IPosStatusSchedule => {
    if (schedules.length === 0) {
      this.status = "UNKNOWN";
      return { status: "UNKNOWN" };
    }

    const scheduleHours = this.getScheduleHoursOfDay(schedules);
    const now: Moment = moment();

    for (let i = 0; i < scheduleHours.length; i = i + 2) {
      const start: Moment = moment(scheduleHours[i], "HH:mm:ss");
      const end: Moment = moment(scheduleHours[i + 1], "HH:mm:ss");

      if (now.isBetween(start, end, undefined, "[]")) {
        this.status = "OPENED";
        this.schedules = [start.format("H:mm"), end.format("H:mm")];
        return {
          status: this.status,
          schedules: this.schedules,
        };
      }
    }

    return { status: "CLOSED" };
  };

  formatScheduleText = (schedules: IPosSchedule[]): string | null => {
    const scheduleHours = this.getScheduleHoursOfDay(schedules);
    const formattedHours = this.formatScheduleHours(scheduleHours);

    if (!formattedHours?.length) return null;

    let scheduleText = `${formattedHours[0]} - ${formattedHours[1]}`;
    for (let i = 2; i < formattedHours.length; i += 2) {
      scheduleText += ` | ${formattedHours[i]} - ${formattedHours[i + 1]}`;
    }

    return scheduleText;
  };

  getRestaurantDescription = (name: string, description?: string): string => {
    return !isEmpty(description) && description !== undefined
      ? description
      : I18n.t("restaurantDetail.restaurantDescription", { name });
  };
}
