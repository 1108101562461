import React from "react";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import { IPos } from "@foodi/core";
import {
  BookingActions,
  HomeScreens,
  PointOfSaleActions,
  POSScreens,
  RestaurantCardViewModel,
} from "@modules";
import {
  webBoxShadow,
  Container,
  Description,
  DescriptionContainer,
  Hours,
  ImageContainer,
  Row,
  Status,
  TextContainer,
  Title,
  Separator,
} from "./RestaurantCard.style";
import { getDecodedId, TestIDs } from "@utils";
import { NavigationProp } from "@react-navigation/native";
import { useDispatch } from "react-redux";

interface IProps {
  restaurantCard: IPos;
  navigation?: NavigationProp<any>;
  currentTab?: number;
}

export const RestaurantCard: React.FC<IProps> = React.memo(
  ({ restaurantCard, navigation, currentTab = 0 }) => {
    const dispatch = useDispatch();

    const restaurantCardViewModel = new RestaurantCardViewModel();

    const uri = restaurantCardViewModel.getUri(restaurantCard);

    const service = restaurantCardViewModel.getService(restaurantCard);

    const posStatus = restaurantCardViewModel.getPosStatusFromSchedules(
      restaurantCard.schedules
    );

    const isOpened = restaurantCardViewModel.getStatus();

    const posStatusLabel = I18n.t(
      `homepage.restaurantCard.${posStatus.status.toLowerCase()}`
    );

    const scheduleHours = restaurantCardViewModel.getScheduleHoursOfDay(
      restaurantCard.schedules
    );

    const formattedHours = restaurantCardViewModel.formatScheduleHours(
      scheduleHours
    );

    const openHours = restaurantCardViewModel.getOpenHours(formattedHours);

    const hoursToShow = restaurantCardViewModel.getHoursToShow(
      formattedHours,
      openHours
    );

    const showMore = restaurantCardViewModel.getShowMore(formattedHours);

    const handleRestaurantPage = () => {
      const tabs = restaurantCardViewModel.getInitTabs();
      dispatch(PointOfSaleActions.setMiniCartStatus(false));
      dispatch(PointOfSaleActions.setMiniBookingCartStatus(false));
      dispatch(
        PointOfSaleActions.setBookingButtonStatus(
          !!restaurantCard.bookingOfferTemplate?.length
        )
      );
      dispatch(
        PointOfSaleActions.setCCButtonStatus(
          !!restaurantCard.activeOfferTemplates?.length
        )
      );
      dispatch(
        BookingActions.setBookingSelected(currentTab === 0 || currentTab === 1)
      );
      dispatch(BookingActions.setSelectedDay(0));
      const id = getDecodedId(restaurantCard?.id);
      //@ts-ignore
      navigation?.push(HomeScreens.POINT_OF_SALE_STACK, {
        screen: POSScreens.RESTAURANT_DETAIL,
        params: {
          id: id?.split(":")?.[1],
          isModify: false,
          isRefillFromCart: false,
          type: tabs[currentTab].type
        },
      });
    };

    return (
      <Container
        testID={TestIDs.home.restaurantCard.actions.goToRestaurantDetail}
        onPress={handleRestaurantPage}
        style={webBoxShadow}
      >
        <Row>
          <ImageContainer
            key={restaurantCard.id}
            resizeMode="cover"
            source={{ uri }}
          />
          <TextContainer>
            <Title>{restaurantCard.name}</Title>
            <DescriptionContainer>
              <Description>{service}</Description>
              {hoursToShow && (
                <Row>
                  <Status isOpened={isOpened}>{posStatusLabel}</Status>
                  <Separator />
                  <Hours>
                    {!isOpened &&
                      `${I18n.t("homepage.restaurantCard.opensTo")} `}
                    {`${hoursToShow}${showMore}`}
                  </Hours>
                </Row>
              )}
            </DescriptionContainer>
          </TextContainer>
        </Row>
      </Container>
    );
  }
);
