import { CloseXButton } from "@atomic";
import { Colors, Spacing } from "@constants";
import { GlobalState, ModalActions } from "@redux";
import React from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  Modal,
  ViewStyle,
  TouchableWithoutFeedback,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

interface IProps {
  children?: React.ReactNode;
  forwardCloseTestID?: string;
  forwardTestID?: string;
  style?: ViewStyle;
  closeXButtonStyle?: ViewStyle;
  closeXContainerStyle?: ViewStyle;
  isOpen?: boolean;
  handleClose?: () => void;
  isMobile?: boolean;
  isFullScreen?: boolean;
  closeOnClickOutside?: boolean;
  isCenter?: boolean;
  paddingVertical?: number | undefined;
  paddingHorizontal?: number | undefined;
  isHideCloseButton?: boolean;
  customComponentCloseButton?: React.ReactElement;
}
export const ModalTemplate: React.FC<IProps> = React.memo(
  ({
    children,
    style,
    closeXButtonStyle,
    closeXContainerStyle,
    forwardCloseTestID,
    forwardTestID,
    isOpen,
    handleClose,
    isMobile,
    isFullScreen,
    paddingVertical,
    paddingHorizontal,
    isHideCloseButton,
    closeOnClickOutside = false,
    isCenter,
    customComponentCloseButton,
  }) => {
    const dispatch = useDispatch();
    const open = useSelector((state: GlobalState) => state?.modal?.open);

    const styles = React.useMemo(
      () =>
        _styles(
          isMobile,
          isFullScreen,
          isCenter,
          paddingVertical,
          paddingHorizontal
        ),
      [isMobile, isFullScreen, isCenter, paddingVertical, paddingHorizontal]
    );

    const handleCloseModal = () => {
      if (handleClose) handleClose();
      else dispatch(ModalActions.setOpen(false));
    };

    return (
      <Modal
        testID={forwardTestID}
        animationType="fade"
        transparent={true}
        visible={isOpen !== undefined ? isOpen : open}
      >
        <View style={styles.container}>
          <TouchableWithoutFeedback
            onPress={closeOnClickOutside ? handleClose : undefined}
          >
            <View style={styles.modalBackground} />
          </TouchableWithoutFeedback>
          <View style={styles.body}>
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={[styles.loginContainer, style]}
            >
              {children}
            </ScrollView>
            {!isHideCloseButton && !customComponentCloseButton && (
              <View style={[styles.closeBtnDiv, closeXContainerStyle]}>
                <CloseXButton
                  forwardTestID={forwardCloseTestID}
                  callback={handleCloseModal}
                  closeXButtonStyle={closeXButtonStyle}
                />
              </View>
            )}
            {!!customComponentCloseButton && (
              <View style={[styles.closeBtnDiv, closeXContainerStyle]}>
                {customComponentCloseButton}
              </View>
            )}
          </View>
        </View>
      </Modal>
    );
  }
);

const _styles = (
  isMobile?: boolean,
  isFullScreen?: boolean,
  isCenter?: boolean,
  paddingVertical: number | undefined = Spacing.XL,
  paddingHorizontal: number | undefined = Spacing.L
) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    body: {
      ...(isCenter
        ? { position: "absolute" }
        : { flex: 1, marginTop: 80, marginBottom: 50 }),
    },
    modalBackground: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: Colors.foodiBlack,
      opacity: 0.5,
    },
    loginContainer: {
      flexGrow: isMobile ? 1 : 0,
      paddingVertical: paddingVertical,
      paddingHorizontal: paddingHorizontal,
      width: isMobile ? "100%" : 520,
      maxHeight: !isFullScreen ? 265 : isMobile ? "100%" : "95vh",
      backgroundColor: Colors.white,
      borderRadius: isMobile ? 0 : 8,
    },
    closeBtnDiv: {
      position: "absolute",
      top: 24,
      right: 24,
    },
  });
