import React from "react";
import { I18n } from "react-redux-i18n";

export const Download = ({size = '20px'}) => (
  <svg width={size} height={size} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>{I18n.t("hoverIcons.download")}</title>
    <g id="Symbols-download" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icons/Download/black" fill="#000000" fillRule="nonzero">
        <path d="M1.875,20 C1.375,20 0.9375,19.8125 0.5625,19.4375 C0.1875,19.0625 0,18.625 0,18.125 L0,13.65625 L1.875,13.65625 L1.875,18.125 L18.125,18.125 L18.125,13.65625 L20,13.65625 L20,18.125 C20,18.625 19.8125,19.0625 19.4375,19.4375 C19.0625,19.8125 18.625,20 18.125,20 L1.875,20 Z M10,15.21875 L3.96875,9.1875 L5.3125,7.84375 L9.0625,11.59375 L9.0625,0 L10.9375,0 L10.9375,11.59375 L14.6875,7.84375 L16.03125,9.1875 L10,15.21875 Z" id="Shape-download"></path>
      </g>
    </g>
  </svg>
);
