import { NavigationProp, Route } from "@react-navigation/native";
import { View, StyleSheet } from "react-native";
import React, { useEffect, useState } from "react";
import { LoaderAnimationComponent, OfferOderContainer, Offers } from "@atomic";
import { HomeScreens, OfferViewModel, OffersActions, OffersThunks } from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { ILanguage, IOfferTemplate } from "@foodi/core";
interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
  isRefillFromCart?: boolean;
  posId: any;
}

export const ClickAndCollectTemplate: React.FC<IProps> = React.memo(
  ({ navigation, route, isRefillFromCart, posId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { type }: any = route?.params || {};
    const [offers, setOffers] = useState<IOfferTemplate[]>([]);
    const [isOfferFetching, setIsOfferFetching] = useState(false);
    const [offerTemplatesError, setOfferTemplatesError] = useState();
    const userInfo = useSelector((state: GlobalState) => state.auth?.userInfo);
    const userLanguage = (userInfo?.language as ILanguage) || ILanguage.fr;
    const dispatch = useDispatch();
    const [offerVM] = useState(new OfferViewModel(dispatch));
    
    const cartCurrentInfo = useSelector(
      (state: GlobalState) => state.pointOfSale?.cartCurrentInfo
    );
    const maxRequestTries = useSelector(
      (state: GlobalState) => state.offers?.maxRequestTries
    );

    const reloadDelayVariable = Number(window.config?.OFFER_PAGE_RELOAD_DELAY_MILLISECONDS);
    const RELOAD_DELAY_SECONDS = reloadDelayVariable / 1000;

    const handleOffers = async () => {
      setIsLoading(true);
      try {
        //@ts-ignore
        const { offerTemplates } = await dispatch(
          OffersThunks.getOfferTemplates({
            type: "CLICK_COLLECT",
            withdrawalType:
              "CONNECTED_LOCKERS,POS_TAKE_AWAY,POS_AT_SITE,TABLE_SERVICE,POS_CLICK_SERVE,INSTANT_CLICK_COLLECT,CLICK_AND_PICK_UP",
            language: userLanguage,
            //@ts-ignore
            posId,
          })
        );
        const currentType = type || cartCurrentInfo?.type;
        setOffers(
            offerVM
                //@ts-ignore
                .sortOffers(offerTemplates.filter(offerTemplate => offerVM.hasAvailableOffer(offerTemplate)), currentType));
        dispatch(OffersActions.setRequestTries(1));
      } catch(error: any) {
        setOfferTemplatesError(error);
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      // FS-8462: This provides an auto refresh to the page in case the offer templates request fails
      if (offerTemplatesError) {
        const reloadTimeout = setTimeout(() => {
          dispatch(OffersActions.setRequestTries(maxRequestTries + 1));
          document.location.reload();
        }, reloadDelayVariable);

        if (maxRequestTries > Number(window.config?.MAX_OFFER_REQUEST_TRIES)) navigation.navigate(HomeScreens.HOME_SCREEN);
        
        return () => clearTimeout(reloadTimeout);
      }
    }, [offerTemplatesError]);

    useEffect(() => {
      handleOffers();
    }, [userInfo, posId]);

    if (isLoading) {
      return (
        <View style={styles.loadingStyle}>
          <LoaderAnimationComponent />
        </View>
      );
    }

    return (
      <>
        <View style={styles.container}>
          <Offers
            isRefillFromCart={isRefillFromCart}
            offers={offers}
            setIsOfferFetching={setIsOfferFetching}
            offerTemplatesError={offerTemplatesError}
            refreshDelay={RELOAD_DELAY_SECONDS}
          />
          <OfferOderContainer
            navigation={navigation}
            route={route}
            isRefillFromCart={isRefillFromCart}
            isOfferFetching={isOfferFetching}
          />
        </View>
      </>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  loadingStyle: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "500px",
  },
});
