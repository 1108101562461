import JsPDF from 'jspdf';

export const htmlStringToPdfDownload = async (htmlString: string, filename: string, isMobile?: boolean) => {
    const pdfDoc = new JsPDF({
      format: "a4",
    });

    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, 'text/html');
    const div = document.createElement('div');

    div.innerHTML = html.body.innerHTML;
    div.style.cssText = html.body.style.cssText;
    div.style.lineHeight = '20px';
    div.style.whiteSpace = 'nowrap';

    const pageCenter = pdfDoc.internal.pageSize.getWidth()  / 2;
    const receiptHalfSize = 35; // approximate half the size of a receipt

    await pdfDoc.html(div, {
      filename,
      html2canvas: {scale: 0.2},
      margin: [6, 0, 6, 0],
      x: pageCenter - receiptHalfSize,  // by doing this calculation we can center the receipt on the page
      y: 0,
      autoPaging: 'text',
    });

    if (isMobile) {
      const blob = pdfDoc.output('blob')
      await triggerFileDownloadToNewTab(blob)
      return;
    }
    pdfDoc.save(filename)
  };


const triggerFileDownloadToNewTab = async (blob: Blob): Promise<void> => {
  const objectURL = URL.createObjectURL(blob);

  const anchor = document.createElement('a');
  anchor.href = objectURL;
  anchor.target = '_black';

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(objectURL);
};