"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.offerTemplatesFixture = void 0;
exports.offerTemplatesFixture = {
    isOffersLoading: false,
    offerTemplates: [
        {
            __typename: "OfferTemplate",
            id: "OfferTemplate:2",
            name: "La Scène Click&Seat",
            withdrawalType: "POS_AT_SITE",
            daysInAdvanceStart: 0,
            daysInAdvanceEnd: 0,
            pos: {
                __typename: "Pos",
                id: "Pos:1092",
            },
            description: "Les bienfaits du smart-up",
            fullDescription: '<p class="subtitle"><span style="background-color:rgb(255,255,255);color:hsl(0,0%,30%);"><strong>Les bienfaits du smart-up</strong></span></p><p>&nbsp;</p><p class="content"><span style="background-color:rgb(255,255,255);color:hsl(0,0%,60%);">Les bienfaits du smart-up</span></p>',
            image: {
                __typename: "Image",
                path: "_app/user-media/46c636010d2bb7f1dd08c18abd575f09836086ab.jpg",
            },
            period: "DAILY",
            salesType: "FREE_SALE",
            bundle: {
                __typename: "Bundle",
                id: "QnVuZGxlOjE=",
                name: "Grand menu",
                description: "Entrée + plat + dessert",
                price: 5,
            },
            paymentMethod: {
                paymentTypes: ['BADGE'],
            },
            admission: "1.00",
            subsidies: "1.00",
            mealHeartRule: {
                information: "Some descriptive text, that will appear to all offerTemplates",
                allowedFamilies: [
                    "STARTER",
                    "DISH",
                    "DESSERT",
                    "BEVERAGE",
                    "SIDE_DISH",
                    "SANDWICH",
                    "SALAD",
                    "SOUP",
                    "BREAD",
                    "PASTRY",
                    "DAIRY",
                    "FRUIT",
                    "MISCELLANEOUS",
                ],
                selectedFamilies: ["DISH", "DESSERT"],
                quantityRules: [
                    {
                        property: "mealQuantity",
                        min: 1,
                        max: 3,
                        __typename: "QuantityRule",
                    },
                    {
                        property: "orderPerDay",
                        min: 0,
                        max: 5,
                        __typename: "QuantityRule",
                    },
                    {
                        property: "sameProductPerOrder",
                        min: 0,
                        max: 2,
                        __typename: "QuantityRule",
                    },
                    {
                        property: "productsPerOrder",
                        min: 0,
                        max: 5,
                        __typename: "QuantityRule",
                    },
                ],
                __typename: "MealHeartRule",
            },
            nextOrderableOffer: {
                __typename: "Offer",
                id: "T2ZmZXI6NDQzMDg=",
                orderRange: "2021-11-10T07:15:00.000+00:00/2021-11-10T15:45:00.000+00:00",
                withdrawRange: "2021-11-10T07:25:00.000+00:00/2021-11-10T16:10:00.000+00:00",
                available: true,
                mealHeartOrderAvailable: true,
                offerItems: [
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzU3",
                        quantityPurchasable: 10,
                        quantityRemaining: 50,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Burger maison bœuf cheddar",
                        inheritedDescription: "Bœuf de race originaire des plaines du Charolais ou du Limousin, de la Raclette de montagne au lait cru fondue, de la poitrine de porc fumée, des oignons confits, de la ciboulette ciselée et notre sauce barbecue artisanale",
                        inheritedFamily: "DISH",
                        inheritedImage: "",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "8.00",
                            currency: "EUR",
                        },
                        quantityOverall: 50,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzQ1",
                        quantityPurchasable: 10,
                        quantityRemaining: 20,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "San Pellegrino 50cl",
                        inheritedDescription: "",
                        inheritedFamily: "BEVERAGE",
                        inheritedImage: "_app/user-media/2cbe19a0942ed61efcdaefb35bed6ee8f2821f35.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "3.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzQ3",
                        quantityPurchasable: 4,
                        quantityRemaining: 4,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "test demo david",
                        inheritedDescription: "test",
                        inheritedFamily: "BEVERAGE",
                        inheritedImage: "_app/user-media/733caebe61f6325bf534d666d92ba45b2345be65.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "0.01",
                            currency: "EUR",
                        },
                        quantityOverall: 4,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzU4",
                        quantityPurchasable: 10,
                        quantityRemaining: 22,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Tarte aux pommes",
                        inheritedDescription: "Tarte mamie",
                        inheritedFamily: "DESSERT",
                        inheritedImage: "_app/user-media/4e2dd07055d8c89546f19e5d27f25613fd9a4645.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "2.00",
                            currency: "EUR",
                        },
                        quantityOverall: 22,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzU5",
                        quantityPurchasable: 10,
                        quantityRemaining: 11,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Coca Zéro 33cl",
                        inheritedDescription: "L'original Coca",
                        inheritedFamily: "BEVERAGE",
                        inheritedImage: "_app/user-media/ff1b62bf52fa019fb5a0b97a1fe5d361d08a3788.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 11,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzQ2",
                        quantityPurchasable: 10,
                        quantityRemaining: 10,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Sac recyclable",
                        inheritedDescription: "long description 2323312312321321321321321321321123213213123213213123213123213213213123213123123213213123213213123123124245345346345435_",
                        inheritedFamily: "MISCELLANEOUS",
                        inheritedImage: "_app/user-media/cb20e254bec3e1984bbc80dfaaae29a8d2bd992f.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "2.45",
                            currency: "EUR",
                        },
                        quantityOverall: 10,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzQ4",
                        quantityPurchasable: 10,
                        quantityRemaining: 27,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Steak haché",
                        inheritedDescription: "",
                        inheritedFamily: "DISH",
                        inheritedImage: null,
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "2.50",
                            currency: "EUR",
                        },
                        quantityOverall: 27,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzY1",
                        quantityPurchasable: 10,
                        quantityRemaining: 32,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Roti de veau à l'italienne",
                        inheritedDescription: "",
                        inheritedFamily: "DISH",
                        inheritedImage: null,
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "4.50",
                            currency: "EUR",
                        },
                        quantityOverall: 32,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzUz",
                        quantityPurchasable: 10,
                        quantityRemaining: 45,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: " Faux-filet rôti",
                        inheritedDescription: "",
                        inheritedFamily: "DISH",
                        inheritedImage: "_app/user-media/1d24fcd99586591df3e0e850bca0e304819baefd.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "5.50",
                            currency: "EUR",
                        },
                        quantityOverall: 45,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzUw",
                        quantityPurchasable: 10,
                        quantityRemaining: 26,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Tarte aux fruits globale 2",
                        inheritedDescription: "La fameuse et l'unique",
                        inheritedFamily: "DISH",
                        inheritedImage: "_app/user-media/cc975663ed590767d14444bd9f0528f9736c1181.png",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "3.30",
                            currency: "EUR",
                        },
                        quantityOverall: 26,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzYw",
                        quantityPurchasable: 10,
                        quantityRemaining: 20,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Sandwich - Test",
                        inheritedDescription: "",
                        inheritedFamily: "SANDWICH",
                        inheritedImage: "_app/user-media/be8dcb8e032fb661877a01bc3191678a0a09bea0.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "10.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzUx",
                        quantityPurchasable: 10,
                        quantityRemaining: 20,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Salade - Test",
                        inheritedDescription: "",
                        inheritedFamily: "SALAD",
                        inheritedImage: "_app/user-media/e8afbf88a8feb312eb14181fa33ef5dd35709f0d.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzU1",
                        quantityPurchasable: 10,
                        quantityRemaining: 21,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Charlotte aux fraises",
                        inheritedDescription: "Charlotte aux fraise BIO La charlotte est un type de dessert moulé, de forme cylindrique ou tronconique, d'environ 10 cm de hauteur, formé d'une croûte faite de biscuits à la cuillère (ou des boudoirs), ou de tranches de pain, ou encore d'une génoise",
                        inheritedFamily: "DESSERT",
                        inheritedImage: "_app/user-media/839153a911a81932f04dae5cce8ebd4cb238facb.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.50",
                            currency: "EUR",
                        },
                        quantityOverall: 21,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzYz",
                        quantityPurchasable: 10,
                        quantityRemaining: 20,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Snacking - Test",
                        inheritedDescription: "",
                        inheritedFamily: "SNACKING",
                        inheritedImage: "_app/user-media/7b52064e1a8d53650ad89d7eae2a287d63591e45.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzYy",
                        quantityPurchasable: 10,
                        quantityRemaining: 19,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Soupe - Test",
                        inheritedDescription: "soupe",
                        inheritedFamily: "SOUP",
                        inheritedImage: "_app/user-media/44ea16c5ede5efe029426be7d22ecb84fb4611e3.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzQ5",
                        quantityPurchasable: 10,
                        quantityRemaining: 20,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Accompagnement - Test",
                        inheritedDescription: "",
                        inheritedFamily: "SIDE_DISH",
                        inheritedImage: "_app/user-media/b3ce0a9ca24b4c5f1afdba4d776cfcc1ca279e17.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzY3",
                        quantityPurchasable: 10,
                        quantityRemaining: 20,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Produit Laitiers - Test",
                        inheritedDescription: null,
                        inheritedFamily: "DAIRY",
                        inheritedImage: "_app/user-media/4e321d8c19cdd4790e5f543b9ebe065f2ddb2cb2.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzY0",
                        quantityPurchasable: 10,
                        quantityRemaining: 20,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Patisseries - Test",
                        inheritedDescription: "",
                        inheritedFamily: "PASTRY",
                        inheritedImage: "_app/user-media/44332245cdebfef9b4479940b404ae347d705af7.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzU2",
                        quantityPurchasable: 10,
                        quantityRemaining: 20,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Fruits - Test ",
                        inheritedDescription: "long ",
                        inheritedFamily: "FRUIT",
                        inheritedImage: "_app/user-media/5cad148e4c6ce4b62d663f5c2ee582213127dde4.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzYx",
                        quantityPurchasable: 10,
                        quantityRemaining: 20,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Boulangerie - Test",
                        inheritedDescription: "",
                        inheritedFamily: "BREAD",
                        inheritedImage: "_app/user-media/4aba91a08275cd87d19a7e458872cc52b80a8dda.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 20,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzU0",
                        quantityPurchasable: 10,
                        quantityRemaining: 50,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "aubergine à la grecque",
                        inheritedDescription: null,
                        inheritedFamily: "STARTER",
                        inheritedImage: null,
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1.00",
                            currency: "EUR",
                        },
                        quantityOverall: 50,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzUy",
                        quantityPurchasable: 8,
                        quantityRemaining: 8,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "assiette grècque",
                        inheritedDescription: "",
                        inheritedFamily: "STARTER",
                        inheritedImage: null,
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "1100.00",
                            currency: "EUR",
                        },
                        quantityOverall: 9,
                    },
                    {
                        __typename: "OfferItem",
                        id: "T2ZmZXJJdGVtOjMzNzY2",
                        quantityPurchasable: 10,
                        quantityRemaining: 50,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "bavette de bœuf",
                        inheritedDescription: "bavette de bœuf description",
                        inheritedFamily: "DISH",
                        inheritedImage: "_app/user-media/354e141ee0d028de3e3ebcb74e68fdf1c96cb197.jpg",
                        inheritedPrice: {
                            __typename: "Amount",
                            amount: "40.00",
                            currency: "EUR",
                        },
                        quantityOverall: 50,
                    },
                ],
            },
            nextOrderableOffers: [{
                    id: "T2ZmZXI6NDQzMDg=",
                    published: true,
                    orderRange: "2022-04-07T06:30:00.000+00:00/2022-04-07T17:00:00.000+00:00",
                    offerItems: [
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzU3",
                            quantityPurchasable: 10,
                            quantityRemaining: 50,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Burger maison bœuf cheddar",
                            inheritedDescription: "Bœuf de race originaire des plaines du Charolais ou du Limousin, de la Raclette de montagne au lait cru fondue, de la poitrine de porc fumée, des oignons confits, de la ciboulette ciselée et notre sauce barbecue artisanale",
                            inheritedFamily: "DISH",
                            inheritedImage: "",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "8.00",
                                currency: "EUR",
                            },
                            quantityOverall: 50,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzQ1",
                            quantityPurchasable: 10,
                            quantityRemaining: 20,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "San Pellegrino 50cl",
                            inheritedDescription: "",
                            inheritedFamily: "BEVERAGE",
                            inheritedImage: "_app/user-media/2cbe19a0942ed61efcdaefb35bed6ee8f2821f35.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "3.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzQ3",
                            quantityPurchasable: 4,
                            quantityRemaining: 4,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "test demo david",
                            inheritedDescription: "test",
                            inheritedFamily: "BEVERAGE",
                            inheritedImage: "_app/user-media/733caebe61f6325bf534d666d92ba45b2345be65.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "0.01",
                                currency: "EUR",
                            },
                            quantityOverall: 4,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzU4",
                            quantityPurchasable: 10,
                            quantityRemaining: 22,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Tarte aux pommes",
                            inheritedDescription: "Tarte mamie",
                            inheritedFamily: "DESSERT",
                            inheritedImage: "_app/user-media/4e2dd07055d8c89546f19e5d27f25613fd9a4645.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "2.00",
                                currency: "EUR",
                            },
                            quantityOverall: 22,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzU5",
                            quantityPurchasable: 10,
                            quantityRemaining: 11,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Coca Zéro 33cl",
                            inheritedDescription: "L'original Coca",
                            inheritedFamily: "BEVERAGE",
                            inheritedImage: "_app/user-media/ff1b62bf52fa019fb5a0b97a1fe5d361d08a3788.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 11,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzQ2",
                            quantityPurchasable: 10,
                            quantityRemaining: 10,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Sac recyclable",
                            inheritedDescription: "long description 2323312312321321321321321321321123213213123213213123213123213213213123213123123213213123213213123123124245345346345435_",
                            inheritedFamily: "MISCELLANEOUS",
                            inheritedImage: "_app/user-media/cb20e254bec3e1984bbc80dfaaae29a8d2bd992f.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "2.45",
                                currency: "EUR",
                            },
                            quantityOverall: 10,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzQ4",
                            quantityPurchasable: 10,
                            quantityRemaining: 27,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Steak haché",
                            inheritedDescription: "",
                            inheritedFamily: "DISH",
                            inheritedImage: null,
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "2.50",
                                currency: "EUR",
                            },
                            quantityOverall: 27,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzY1",
                            quantityPurchasable: 10,
                            quantityRemaining: 32,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Roti de veau à l'italienne",
                            inheritedDescription: "",
                            inheritedFamily: "DISH",
                            inheritedImage: null,
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "4.50",
                                currency: "EUR",
                            },
                            quantityOverall: 32,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzUz",
                            quantityPurchasable: 10,
                            quantityRemaining: 45,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: " Faux-filet rôti",
                            inheritedDescription: "",
                            inheritedFamily: "DISH",
                            inheritedImage: "_app/user-media/1d24fcd99586591df3e0e850bca0e304819baefd.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "5.50",
                                currency: "EUR",
                            },
                            quantityOverall: 45,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzUw",
                            quantityPurchasable: 10,
                            quantityRemaining: 26,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Tarte aux fruits globale 2",
                            inheritedDescription: "La fameuse et l'unique",
                            inheritedFamily: "DISH",
                            inheritedImage: "_app/user-media/cc975663ed590767d14444bd9f0528f9736c1181.png",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "3.30",
                                currency: "EUR",
                            },
                            quantityOverall: 26,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzYw",
                            quantityPurchasable: 10,
                            quantityRemaining: 20,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Sandwich - Test",
                            inheritedDescription: "",
                            inheritedFamily: "SANDWICH",
                            inheritedImage: "_app/user-media/be8dcb8e032fb661877a01bc3191678a0a09bea0.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "10.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzUx",
                            quantityPurchasable: 10,
                            quantityRemaining: 20,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Salade - Test",
                            inheritedDescription: "",
                            inheritedFamily: "SALAD",
                            inheritedImage: "_app/user-media/e8afbf88a8feb312eb14181fa33ef5dd35709f0d.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzU1",
                            quantityPurchasable: 10,
                            quantityRemaining: 21,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Charlotte aux fraises",
                            inheritedDescription: "Charlotte aux fraise BIO La charlotte est un type de dessert moulé, de forme cylindrique ou tronconique, d'environ 10 cm de hauteur, formé d'une croûte faite de biscuits à la cuillère (ou des boudoirs), ou de tranches de pain, ou encore d'une génoise",
                            inheritedFamily: "DESSERT",
                            inheritedImage: "_app/user-media/839153a911a81932f04dae5cce8ebd4cb238facb.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.50",
                                currency: "EUR",
                            },
                            quantityOverall: 21,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzYz",
                            quantityPurchasable: 10,
                            quantityRemaining: 20,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Snacking - Test",
                            inheritedDescription: "",
                            inheritedFamily: "SNACKING",
                            inheritedImage: "_app/user-media/7b52064e1a8d53650ad89d7eae2a287d63591e45.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzYy",
                            quantityPurchasable: 10,
                            quantityRemaining: 19,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Soupe - Test",
                            inheritedDescription: "soupe",
                            inheritedFamily: "SOUP",
                            inheritedImage: "_app/user-media/44ea16c5ede5efe029426be7d22ecb84fb4611e3.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzQ5",
                            quantityPurchasable: 10,
                            quantityRemaining: 20,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Accompagnement - Test",
                            inheritedDescription: "",
                            inheritedFamily: "SIDE_DISH",
                            inheritedImage: "_app/user-media/b3ce0a9ca24b4c5f1afdba4d776cfcc1ca279e17.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzY3",
                            quantityPurchasable: 10,
                            quantityRemaining: 20,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Produit Laitiers - Test",
                            inheritedDescription: null,
                            inheritedFamily: "DAIRY",
                            inheritedImage: "_app/user-media/4e321d8c19cdd4790e5f543b9ebe065f2ddb2cb2.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzY0",
                            quantityPurchasable: 10,
                            quantityRemaining: 20,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Patisseries - Test",
                            inheritedDescription: "",
                            inheritedFamily: "PASTRY",
                            inheritedImage: "_app/user-media/44332245cdebfef9b4479940b404ae347d705af7.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzU2",
                            quantityPurchasable: 10,
                            quantityRemaining: 20,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Fruits - Test ",
                            inheritedDescription: "long ",
                            inheritedFamily: "FRUIT",
                            inheritedImage: "_app/user-media/5cad148e4c6ce4b62d663f5c2ee582213127dde4.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzYx",
                            quantityPurchasable: 10,
                            quantityRemaining: 20,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "Boulangerie - Test",
                            inheritedDescription: "",
                            inheritedFamily: "BREAD",
                            inheritedImage: "_app/user-media/4aba91a08275cd87d19a7e458872cc52b80a8dda.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 20,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzU0",
                            quantityPurchasable: 10,
                            quantityRemaining: 50,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "aubergine à la grecque",
                            inheritedDescription: null,
                            inheritedFamily: "STARTER",
                            inheritedImage: null,
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1.00",
                                currency: "EUR",
                            },
                            quantityOverall: 50,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzUy",
                            quantityPurchasable: 8,
                            quantityRemaining: 8,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "assiette grècque",
                            inheritedDescription: "",
                            inheritedFamily: "STARTER",
                            inheritedImage: null,
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "1100.00",
                                currency: "EUR",
                            },
                            quantityOverall: 9,
                        },
                        {
                            __typename: "OfferItem",
                            id: "T2ZmZXJJdGVtOjMzNzY2",
                            quantityPurchasable: 10,
                            quantityRemaining: 50,
                            baking: null,
                            articleTags: [],
                            articleCertifications: [],
                            inheritedLabel: "bavette de bœuf",
                            inheritedDescription: "bavette de bœuf description",
                            inheritedFamily: "DISH",
                            inheritedImage: "_app/user-media/354e141ee0d028de3e3ebcb74e68fdf1c96cb197.jpg",
                            inheritedPrice: {
                                __typename: "Amount",
                                amount: "40.00",
                                currency: "EUR",
                            },
                            quantityOverall: 50,
                        },
                    ],
                    __typename: "Offer"
                }]
        },
        {
            __typename: "OfferTemplate",
            name: "Daily Monop",
            withdrawalType: "POS_AT_SITE",
            daysInAdvanceStart: 0,
            daysInAdvanceEnd: 7,
            pos: {
                __typename: "Pos",
                id: "Pos:1092",
            },
            description: "Sandwichs et coca pour manger sur le pouce!",
            fullDescription: "",
            image: {
                __typename: "Image",
                path: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
            },
            //@ts-ignore
            period: "DAILY",
            //@ts-ignore
            salesType: "FREE_SALE",
            bundle: null,
            nextOrderableOffer: {
                id: "Offer:2",
                orderRange: "2021-11-11T06:00:00.000+00:00/2021-12-11T22:00:00.000+00:00",
                withdrawRange: "2021-11-11T06:00:00.000+00:00/2021-12-11T22:00:00.000+00:00",
                available: true,
                mealHeartOrderAvailable: true,
                offerItems: [
                    {
                        id: "OfferItem:3",
                        quantityPurchasable: 10,
                        quantityRemaining: 297,
                        baking: ["WELLDONE", "RARE"],
                        articleTags: [
                            {
                                numericId: 1,
                                label: "Suggestion du jour",
                                __typename: "ArticleTag",
                            },
                        ],
                        articleCertifications: [
                            {
                                numericId: 1,
                                label: "BBC",
                                __typename: "ArticleCertification",
                            },
                        ],
                        inheritedLabel: "Salade de pates",
                        inheritedFamily: "DISH",
                        inheritedImage: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
                        inheritedPrice: {
                            amount: "3.00",
                            currency: "EUR",
                            __typename: "Amount",
                        },
                        quantityOverall: 300,
                        __typename: "OfferItem",
                    },
                    {
                        id: "OfferItem:4",
                        quantityPurchasable: 10,
                        quantityRemaining: 44,
                        baking: null,
                        articleTags: [
                            {
                                numericId: 1,
                                label: "Suggestion du jour",
                                __typename: "ArticleTag",
                            },
                        ],
                        articleCertifications: [
                            {
                                numericId: 1,
                                label: "BBC",
                                __typename: "ArticleCertification",
                            },
                        ],
                        inheritedLabel: "Coca Cola",
                        inheritedFamily: "BEVERAGE",
                        inheritedImage: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
                        inheritedPrice: {
                            amount: "0.00",
                            currency: "EUR",
                            __typename: "Amount",
                        },
                        quantityOverall: 50,
                        __typename: "OfferItem",
                    },
                ],
                __typename: "Offer",
            },
            nextOrderableOffers: [{
                    id: "Offer:2",
                    published: true,
                    orderRange: "2022-04-07T06:30:00.000+00:00/2022-04-07T17:00:00.000+00:00",
                    offerItems: [
                        {
                            id: "OfferItem:3",
                            quantityPurchasable: 10,
                            quantityRemaining: 297,
                            baking: ["WELLDONE", "RARE"],
                            articleTags: [
                                {
                                    numericId: 1,
                                    label: "Suggestion du jour",
                                    __typename: "ArticleTag",
                                },
                            ],
                            articleCertifications: [
                                {
                                    numericId: 1,
                                    label: "BBC",
                                    __typename: "ArticleCertification",
                                },
                            ],
                            inheritedLabel: "Salade de pates",
                            inheritedFamily: "DISH",
                            inheritedImage: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
                            inheritedPrice: {
                                amount: "3.00",
                                currency: "EUR",
                                __typename: "Amount",
                            },
                            quantityOverall: 300,
                            __typename: "OfferItem",
                        },
                        {
                            id: "OfferItem:4",
                            quantityPurchasable: 10,
                            quantityRemaining: 44,
                            baking: null,
                            articleTags: [
                                {
                                    numericId: 1,
                                    label: "Suggestion du jour",
                                    __typename: "ArticleTag",
                                },
                            ],
                            articleCertifications: [
                                {
                                    numericId: 1,
                                    label: "BBC",
                                    __typename: "ArticleCertification",
                                },
                            ],
                            inheritedLabel: "Coca Cola",
                            inheritedFamily: "BEVERAGE",
                            inheritedImage: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
                            inheritedPrice: {
                                amount: "0.00",
                                currency: "EUR",
                                __typename: "Amount",
                            },
                            quantityOverall: 50,
                            __typename: "OfferItem",
                        },
                    ],
                    __typename: "Offer"
                }]
        },
        {
            __typename: "OfferTemplate",
            id: "OfferTemplate:6",
            name: "Formule",
            pos: {
                __typename: "Pos",
                id: "Pos:1092",
            },
            description: "Entree, plat et dessert obligatoire !",
            fullDescription: "",
            image: {
                __typename: "Image",
                path: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
            },
            //@ts-ignore
            period: "DAILY",
            //@ts-ignore
            salesType: "BUNDLE",
            bundle: {
                __typename: "Bundle",
                id: "Bundle:1",
                name: "Grand menu",
                description: "Entrée + plat + dessert",
                price: 5,
            },
            nextOrderableOffer: {
                id: "Offer:3",
                orderRange: "2021-11-11T06:00:00.000+00:00/2021-12-11T22:00:00.000+00:00",
                withdrawRange: "2021-11-11T06:00:00.000+00:00/2021-12-11T22:00:00.000+00:00",
                available: true,
                mealHeartOrderAvailable: false,
                offerItems: [
                    {
                        id: "OfferItem:5",
                        quantityPurchasable: 10,
                        quantityRemaining: 50,
                        baking: null,
                        articleTags: [
                            {
                                numericId: 2,
                                label: "Menu malin",
                                __typename: "ArticleTag",
                            },
                        ],
                        articleCertifications: [
                            {
                                numericId: 2,
                                label: "BIO",
                                __typename: "ArticleCertification",
                            },
                        ],
                        inheritedLabel: "Gateau anniversaire",
                        inheritedFamily: "DESSERT",
                        inheritedImage: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
                        inheritedPrice: {
                            amount: "14.60",
                            currency: "EUR",
                            __typename: "Amount",
                        },
                        quantityOverall: 50,
                        __typename: "OfferItem",
                    },
                    {
                        id: "OfferItem:25",
                        quantityPurchasable: 6,
                        quantityRemaining: 6,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Coca Cola",
                        inheritedFamily: "BEVERAGE",
                        inheritedImage: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
                        inheritedPrice: {
                            amount: "0.00",
                            currency: "EUR",
                            __typename: "Amount",
                        },
                        quantityOverall: 6,
                        __typename: "OfferItem",
                    },
                ],
                __typename: "Offer",
            },
        },
        {
            __typename: "OfferTemplate",
            id: "OfferTemplate:3",
            name: "Gateau anniversaire",
            withdrawalType: "POS_AT_SITE",
            daysInAdvanceStart: 0,
            daysInAdvanceEnd: 0,
            pos: {
                __typename: "Pos",
                id: "Pos:1092",
            },
            description: "Fetez l'anniversaire de vos collègues à la cantine!",
            fullDescription: "",
            image: {
                __typename: "Image",
                path: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
            },
            //@ts-ignore
            period: "DAILY",
            //@ts-ignore
            salesType: "FREE_SALE",
            bundle: null,
            nextOrderableOffer: {
                id: "Offer:4",
                orderRange: "2021-11-11T06:00:00.000+00:00/2021-12-11T22:00:00.000+00:00",
                withdrawRange: "2021-11-11T06:00:00.000+00:00/2021-12-11T22:00:00.000+00:00",
                available: true,
                mealHeartOrderAvailable: true,
                offerItems: [
                    {
                        id: "OfferItem:5",
                        quantityPurchasable: 10,
                        quantityRemaining: 50,
                        baking: null,
                        articleTags: [
                            {
                                numericId: 2,
                                label: "Menu malin",
                                __typename: "ArticleTag",
                            },
                        ],
                        articleCertifications: [
                            {
                                numericId: 2,
                                label: "BIO",
                                __typename: "ArticleCertification",
                            },
                        ],
                        inheritedLabel: "Gateau anniversaire",
                        inheritedFamily: "DESSERT",
                        inheritedImage: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
                        inheritedPrice: {
                            amount: "14.60",
                            currency: "EUR",
                            __typename: "Amount",
                        },
                        quantityOverall: 50,
                        __typename: "OfferItem",
                    },
                    {
                        id: "OfferItem:25",
                        quantityPurchasable: 6,
                        quantityRemaining: 6,
                        baking: null,
                        articleTags: [],
                        articleCertifications: [],
                        inheritedLabel: "Coca Cola",
                        inheritedFamily: "BEVERAGE",
                        inheritedImage: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
                        inheritedPrice: {
                            amount: "0.00",
                            currency: "EUR",
                            __typename: "Amount",
                        },
                        quantityOverall: 6,
                        __typename: "OfferItem",
                    },
                ],
                __typename: "Offer",
            },
        },
        {
            __typename: "OfferTemplate",
            id: "OfferTemplate:1",
            name: "Panier bio",
            withdrawalType: "POS_TAKE_AWAY",
            daysInAdvanceStart: 0,
            daysInAdvanceEnd: 0,
            pos: {
                __typename: "Pos",
                id: "Pos:1092",
            },
            description: "Vos paniers bios frais à retirer tous les jeudis entre 11 et 13h. Commandez au moins une semaine à l'avance!",
            fullDescription: "",
            image: {
                __typename: "Image",
                path: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
            },
            //@ts-ignore
            period: "WEEKLY_MON",
            //@ts-ignore
            salesType: "FREE_SALE",
            bundle: null,
            nextOrderableOffer: null,
        },
        {
            __typename: "TableServiceOfferTemplate",
            id: "TableServiceOfferTemplate:4",
            name: "Service à table",
            pos: {
                __typename: "Pos",
                id: "Pos:1092",
            },
            description: "Votre repas, sans attente en caisse",
            fullDescription: "",
            image: {
                __typename: "Image",
                path: "_app/user-media/article/00006-image-4ad2b1b3e799319821c0d9552f20f5cef198728a.png",
            },
            //@ts-ignore
            salesType: "FREE_SALE",
            bundle: null,
            nextOrderableOffer: null,
        },
    ],
};
