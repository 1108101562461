import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import {
  InitializeAuth0Client,
  Login,
  RetrieveTokens,
  RefreshToken,
  GetPaymentRequest,
  FPaymentsService,
  GetPaymentStatus,
  GetBalance,
  GetGuestPaymentProfile,
  FAccountService,
  FGuestService,
  GetServices,
  GetBrand,
  GetMarketingCards,
  GetPublishedMarketingCardsNew,
  GetOfferTemplates,
  GetPointsOfSaleWithMenu,
  GetTicket,
  GetI18nProviderLocale,
  GetProfileSectionsData,
  GetTransactions,
  GetUserInfoForTracking,
  GetUserProfile,
  FTicketRepository,
  FUserRepository,
  GetOrders,
  GetOrder,
  GetOrderReceipt,
  GetOrderCC,
  GetBooking,
  GetBookingOrders,
  GetHoldingWithRecipeStatus,
  GetPos,
  GetOnePos,
  GetPointsOfSale,
  InMemoryPointsOfSaleService,
  onePosFixture,
  InMemoryOfferService,
  offerTemplatesFixture,
  UpsertOrder,
  ConfirmOrder,
  GetCartSummaryAdmissionAndPrice,
  GetOfferSlots,
  CreateBookingOrder,
  UpdateBookingOrderSlot,
  CancelBookingOrder,
  CancelOrder,
  GetBookingTemplateOffer,
  bookingTemplateOfferFixture,
  UpdateOptins,
  UpdateOptouts,
  UpdateLanguage,
  FProfileService,
  UpdateVirtualTicket,
  InMemoryMarketingCardService,
  newsFixtures,
  brandFixtures,
  GetBrandTheme,
  FBrandThemeGateway,
  CheckEmail,
  GetNearHoldings,
  SignUp,
  InMemoryHoldingRepository,
  holdingFixtures,
  AddUserHoldingView,
  SwitchUserHoldingView,
  GetEdenredDailyBalance,
  GetEdenredAuthorizationURL,
  GenerateEdenredTokens,
  checkEmailExistFixtures,
  InMemoryAuthenticationService,
  GetUserHoldingView,
  MigrateToHoldingView,
  DeleteUserHoldingView,
  UpdateUserHoldingView,
  SendUserEmailVerification,
  InMemoryAccountService,
  LoginSSO,
  offerSlotsFixture,
  GetOffer,
  InMemoryTableService,
  checkTablesAvailabilityFixture,
  CheckTablesAvailability,
  DeleteAccount,
  InMemoryOrderRepository,
  LoginIDP,
  GetScolapassPayments,
  GetScolapassInvoices,
  GetInvoiceScolapassFile,
  SendResetPasswordEmail,
  GetAllOrders,
  InMemoryPickupPoints,
  pickupPointsFixture,
  GetPickupPoints,
  userLastUsedPickupPointFixture,
  GetUserLastUsedPickupPoint,
  AddOrderRating,
  RecentOrdersToRate, DiscardOrderRatingNotification,
} from '@foodi/core';
import { Dependencies } from "./dependencies";
import { getApolloClient, initializeApolloClient } from "../apollo";

export const InMemoryDependencies = (): Dependencies => {
  initializeApolloClient();
  const client: ApolloClient<NormalizedCacheObject> = getApolloClient();

  const brandThemeGateway = new FBrandThemeGateway(
    window.config.CONFIGURATION_TOOL_URL,
    { "x-api-key": window.config.CONFIGURATION_TOOL_API_KEY }
  );

  const authenticationService = new InMemoryAuthenticationService(
    checkEmailExistFixtures
  );
  const paymentsService = new FPaymentsService(client);
  const accountService = new FAccountService(client);
  const inMemoryAccountService = new InMemoryAccountService();
  const guestService = new FGuestService(client);
  const holdingRepository = new InMemoryHoldingRepository(
    holdingFixtures,
    true
  );
  const orderRepository = new InMemoryOrderRepository(true);
  const marketingCardService = new InMemoryMarketingCardService(
    newsFixtures,
    brandFixtures
  );
  const offerService = new InMemoryOfferService(
    offerTemplatesFixture,
    bookingTemplateOfferFixture,
    offerSlotsFixture
  );
  const pointsOfSaleService = new InMemoryPointsOfSaleService(onePosFixture);
  const ticketRepository = new FTicketRepository(client);
  const userRepository = new FUserRepository(client);
  const profileService = new FProfileService(client);
  const tableService = new InMemoryTableService(checkTablesAvailabilityFixture);
  const pickupPoints = new InMemoryPickupPoints(pickupPointsFixture);
  const lastUsedPickupPoint = new InMemoryPickupPoints(userLastUsedPickupPointFixture);

  return {
    initializeAuth0Client: new InitializeAuth0Client(authenticationService),
    login: new Login(authenticationService),
    retrieveTokens: new RetrieveTokens(authenticationService),
    refreshToken: new RefreshToken(authenticationService),
    getPaymentRequest: new GetPaymentRequest(paymentsService),
    getPaymentStatus: new GetPaymentStatus(paymentsService),
    getBalance: new GetBalance(accountService),
    getGuestPaymentProfile: new GetGuestPaymentProfile(guestService),
    getServices: new GetServices(holdingRepository),
    getBrand: new GetBrand(marketingCardService),
    getMarketingCards: new GetMarketingCards(marketingCardService),
    getPublishedMarketingCardsNew: new GetPublishedMarketingCardsNew(
      marketingCardService
    ),
    getOfferTemplates: new GetOfferTemplates(offerService),
    getOfferSlots: new GetOfferSlots(offerService),
    getOffer: new GetOffer(offerService),
    getPointsOfSaleWithMenu: new GetPointsOfSaleWithMenu(pointsOfSaleService),
    getTicket: new GetTicket(ticketRepository),
    getI18nProviderLocale: new GetI18nProviderLocale(userRepository),
    getProfileSectionsData: new GetProfileSectionsData(userRepository),
    getTransactions: new GetTransactions(userRepository),
    getScolapassPayments: new GetScolapassPayments(userRepository),
    getUserInfoForTracking: new GetUserInfoForTracking(userRepository),
    getUserProfile: new GetUserProfile(userRepository),
    getOrders: new GetOrders(orderRepository),
    getAllOrders: new GetAllOrders(orderRepository),
    getOrder: new GetOrder(orderRepository),
    getOrderReceipt: new GetOrderReceipt(orderRepository),
    getOrderCC: new GetOrderCC(orderRepository),
    getBooking: new GetBooking(orderRepository),
    upsertOrder: new UpsertOrder(orderRepository),
    confirmOrder: new ConfirmOrder(orderRepository),
    getCartSummaryAdmissionAndPrice: new GetCartSummaryAdmissionAndPrice(
      orderRepository
    ),
    getBookingOrders: new GetBookingOrders(orderRepository),
    getHoldingWithRecipeStatus: new GetHoldingWithRecipeStatus(
      holdingRepository
    ),
    getPos: new GetPos(pointsOfSaleService),
    getOnePos: new GetOnePos(pointsOfSaleService),
    getPointsOfSale: new GetPointsOfSale(pointsOfSaleService),
    createBookingOrder: new CreateBookingOrder(orderRepository),
    updateBookingOrderSlot: new UpdateBookingOrderSlot(orderRepository),
    cancelBookingOrder: new CancelBookingOrder(orderRepository),
    cancelOrder: new CancelOrder(orderRepository),
    addOrderRating: new AddOrderRating(orderRepository),
    discardOrderRatingNotification: new DiscardOrderRatingNotification(orderRepository),
    recentOrdersToRate: new RecentOrdersToRate(orderRepository),
    getBookingTemplateOffer: new GetBookingTemplateOffer(offerService),
    updateLanguage: new UpdateLanguage(profileService),
    updateOptins: new UpdateOptins(profileService),
    updateOptouts: new UpdateOptouts(profileService),
    updateVirtualTicket: new UpdateVirtualTicket(profileService),
    getBrandTheme: new GetBrandTheme(brandThemeGateway),
    checkEmail: new CheckEmail(authenticationService),
    signUp: new SignUp(authenticationService),
    getNearHoldings: new GetNearHoldings(holdingRepository),
    addUserHoldingView: new AddUserHoldingView(holdingRepository),
    switchUserHoldingView: new SwitchUserHoldingView(holdingRepository),
    getUserHoldingView: new GetUserHoldingView(holdingRepository),
    getEdenredAuthorizationURL: new GetEdenredAuthorizationURL(paymentsService),
    getEdenredDailyBalance: new GetEdenredDailyBalance(paymentsService),
    generateEdenredTokens: new GenerateEdenredTokens(paymentsService),
    migrateToHoldingView: new MigrateToHoldingView(holdingRepository),
    deleteUserHoldingView: new DeleteUserHoldingView(holdingRepository),
    updateUserHoldingView: new UpdateUserHoldingView(holdingRepository),
    sendUserEmailVerification: new SendUserEmailVerification(
      inMemoryAccountService
    ),
    loginSSO: new LoginSSO(authenticationService),
    checkTablesAvailability: new CheckTablesAvailability(tableService),
    deleteAccount: new DeleteAccount(authenticationService),
    loginIDP: new LoginIDP(authenticationService),
    getScolapassInvoices: new GetScolapassInvoices(userRepository),
    getInvoiceScolapassFile: new GetInvoiceScolapassFile(userRepository),
    sendResetPasswordEmail: new SendResetPasswordEmail(authenticationService),
    getPickupPoints: new GetPickupPoints(pickupPoints),
    getUserLastUsedPickupPoint: new GetUserLastUsedPickupPoint(lastUsedPickupPoint)
  };
};
