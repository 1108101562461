"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POINTS_OF_SALE_FRAGMENT = void 0;
const client_1 = require("@apollo/client");
exports.POINTS_OF_SALE_FRAGMENT = client_1.gql `
fragment pointsOfSaleFragment on Pos {
  id
  concept {
    name
    image
    __typename
  }
  type
  atSiteTakeAwayType
  name
  status @client
  schedules {
    days
    hours
    __typename
  }
  zone {
    id
    name
    __typename
  }
  image
  content
  typeModel {
    id
    name
    group
    image
    __typename
  }
  __typename
}
`;
